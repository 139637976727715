import { Col, Form, Radio, Row } from 'antd';
import React, { useState } from 'react';
import { FormApi } from 'final-form';

interface IPropRadio {
  form: FormApi<any, Partial<any>>;
  formName: string;
  input: any;
  idComponent: string;
  meta: any;
  disabled: boolean;
  dataOption: any;
  formClassName?: string;
  addtionalLabel?: string;
  addtionalFunction?: () => void;
  className?: string;
  classNameLabel?: string;
  layout?: string;
  label?: string;
  labelIsRequired?: boolean;
  initialValues?: any;
}

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginBottom: '7px',
};

export default function RadioButtonIconRFF(props: IPropRadio) {
  const {
    form,
    meta: { error },
    classNameLabel,
    label,
    dataOption,
    layout,
    formClassName,
    addtionalLabel,
    addtionalFunction,
    labelIsRequired,
    input,
    idComponent,
    disabled,
    className,
    initialValues,
  } = props;
  const [count] = useState(0);
  const handleOnChange = e => {
    input.onChange(e);
    form.change(`${e.target.value}`, null);
    form.change(`${e.target.value}Cover`, null);
    form.change('file', null);
    form.change('fileName', null);
    form.change('thumbnail', null);
    if (addtionalFunction) addtionalFunction();
    if (initialValues) {
      initialValues.file = null;
      initialValues.fileName = null;
      initialValues.thumbnail = null;
      initialValues.fileType = e.target.value;
    }
  };

  const additionalLabel = () => {
    if (addtionalLabel) {
      return ` ${addtionalLabel}`;
    } else {
      return null;
    }
  };

  const labelTitle = label ? (
    <span>
      <b className={`capital ${classNameLabel}`}>{label}</b>
      <span style={{ color: '#BFBFBF' }}>{additionalLabel()}</span>
      {labelIsRequired ? <span className="labelRequired">*</span> : null}
    </span>
  ) : null;

  const items = dataOption.map(item => {
    return (
      <Col span={12} key={item.id}>
        <Radio.Group
          id={idComponent}
          onChange={e => handleOnChange(e)}
          disabled={disabled}
          value={input.value}
          onBlur={input.onBlur}
          className={className}
        >
          <Radio.Button
            value={item.id}
            key={`radioValue${item.id}`}
            id={`radioValue${item.id}`}
            style={layout === 'vertical' ? radioStyle : undefined}
            className={item.className}
            disabled={disabled}
          >
            {item.icon ? item.icon : null}
            {item.component === undefined ? item.name : item.component}
          </Radio.Button>
        </Radio.Group>
      </Col>
    );
  });
  return (
    <Form.Item
      validateStatus={count > 0 && error !== undefined ? 'error' : ''}
      help={count > 0 && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      <Row gutter={24}>{items}</Row>
    </Form.Item>
  );
}
