import React, { useState } from 'react';
import NonSelfLearningComponent from '../Component/NonSelfLearningComponent';
import ModalNonSelfLearningContainer from './ModalNonSelfLearningContainer';
import { toastr } from 'react-redux-toastr';
import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import {
  deleteFeedbackBankAPI,
  getFeedbackBankAPI,
} from '../../../Services/ELearningFeedbackBank/feedbackBank.api';
import CButton from '../../../Assets/Components/CButton';
import { getElearningChannelAPI } from '../../../Services/ElearningContentManagement/channel.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function NonSelfLearningContainer() {
  const [filter, setFilter] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(); // id nya
  const [selectedData, setSelectedData] = useState();

  const {
    data: dataFeedback,
    isLoading,
    refetch,
  } = useQuery(
    ['getContentManagementChannelAPI', 'nonSelfLearning'],
    getFeedbackBankAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  const {
    data: dataChannel,
    isLoading: isLoadingChannel,
    refetch: refetchChannel,
  } = useQuery(['getChannelAPI'], getElearningChannelAPI, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const deleteFeedback = useMutation(
    (vals: any) => {
      return deleteFeedbackBankAPI(vals);
    },
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success(
          <span className="capitalFirst">
            <FormattedMessage id="deleteFeedbackBankSuccess" />
          </span>
        );
        refetch();
      },
    }
  );

  const handleRefresh = () => {
    setSelectedChannel(undefined);
    setSelectedData(undefined);
    setFilter(undefined);
    refetch();
    refetchChannel();
  };

  const handleOnClick = () => setShowModal(true);

  const handleFilter = () => setFilter(selectedChannel);

  const handleResetFilter = () => {
    setSelectedChannel(undefined);
    setFilter(undefined);
    refetch();
  };

  const renderAction = row => {
    const rowOriginal = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        onOk: () => deleteFeedback.mutate(rowOriginal.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(
        `Delete Feedback Category "${rowOriginal.category}"?`,
        toastrConfirmOptions
      );
    };

    const handleEdit = () => {
      setSelectedData(rowOriginal);
      setShowModal(true);
    };

    const dataButton = [
      {
        id: 'btnDeleteSelfLearningFeedbackBank',
        icon: 'DeleteOutlined',
        className: 'btnDelete',
        onClick: () => handleDelete(),
      },
      {
        id: 'btnUpdateSelfLearningFeedbackBank',
        icon: 'EditOutlined',
        className: 'btnEdit',
        onClick: () => handleEdit(),
      },
    ];

    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      Header: 'Channel',
      accessor: 'channelName',
    },
    {
      Header: 'Feedback Category',
      accessor: 'category',
    },
    {
      Header: 'Action',
      Cell: row => renderAction(row),
    },
  ];

  const data = dataFeedback
    ? filter
      ? dataFeedback.filter(item => item.channelId === filter)
      : dataFeedback
    : [];

  return (
    <>
      <NonSelfLearningComponent
        columnData={columnData}
        channelList={dataChannel || []}
        data={data}
        isLoading={isLoading || isLoadingChannel}
        handleRefresh={handleRefresh}
        handleFilter={handleFilter}
        handleResetFilter={handleResetFilter}
        handleOnClick={handleOnClick}
        selectedChannel={selectedChannel}
        setSelectedChannel={setSelectedChannel}
      />
      <ModalNonSelfLearningContainer
        channelData={dataChannel || []}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
