import {
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  TimePicker,
} from 'antd';
import React, { useState } from 'react';

import CIcon from './CIcon';
import moment from 'moment';

interface IProps {
  input;
  idComponent;
  placeholder?;
  meta;
  label?;
  disabled?;
  formClassName?;
  addtionalLabel?;
  className?;
  labelIsRequired?;
  allowClear?;
  additionalOnChange?;
  addtionalCaption?;
  labeldisclaimer?;
}

interface IPropsText extends IProps {
  icon?;
  disableOnChange?;
  textArea?;
  classNameLabel?;
  maxlength?;
  additionalValue?;
  textPrefix?;
  addtionalFunctionOnChange?;
}

interface IPropsTextNumber extends IProps {
  icon?;
  min?;
  max?;
  defaultValue?;
  description?;
  addtionalOnChange?;
}

interface IPropsSelect extends IProps {
  icon?;
  data;
  dataItem?;
  defaultItemName;
  additionalHandleChangeFunc?;
  combineValue?;
  disabledByKey?;
  disabledByKeyNegation?;
  customOption?;
  showSearch?;
  mode?: 'multiple' | 'tags';
  handleSearch?;
  loading?;
}

const defaultStyle = { color: 'rgba(0,0,0,.25)' };
const defaultStyleInput = { width: '100%' };

export const InputText = ({
  input,
  meta: { touched, error },
  placeholder,
  idComponent,
  icon,
  label,
  disabled,
  disableOnChange,
  classNameLabel,
  className,
  textArea,
  formClassName,
  maxlength,
  additionalValue,
  addtionalLabel,
  addtionalCaption,
  textPrefix,
  labelIsRequired,
  addtionalFunctionOnChange,
  allowClear,
  labeldisclaimer,
}: IPropsText) => {
  const handleOnChange = e => {
    input.onChange(e.target.value);
    if (addtionalFunctionOnChange) {
      addtionalFunctionOnChange(e.target.value);
    }
  };
  if (additionalValue !== undefined) {
    input.onChange(additionalValue);
  }

  const additionalLabel = () => {
    if (addtionalLabel) {
      return ` ${addtionalLabel}`;
    } else {
      return null;
    }
  };

  const additionalCaption = () => {
    if (addtionalCaption) {
      return ` ${addtionalCaption}`;
    } else {
      return null;
    }
  };

  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };

  const disabledOnChange = () => {
    if (disableOnChange) {
      return undefined;
    } else {
      return handleOnChange;
    }
  };

  const labelTitle = label ? (
    <span>
      <b className={`capital ${classNameLabel}`}>{label}</b>
      <span style={{ color: '#BFBFBF' }}>{additionalLabel()}</span>
      {lebelRequired()}
    </span>
  ) : null;
  const prefixIcon = () => {
    if (textPrefix) {
      return textPrefix;
    } else if (icon) {
      return <CIcon type={icon} style={defaultStyle} />;
    } else {
      return null;
    }
  };

  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      {textArea ? (
        <Input.TextArea
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={input.onBlur}
          id={idComponent}
          value={input.value}
          autoSize={{ minRows: 4 }}
          disabled={disabled}
          className={className}
          maxLength={maxlength}
        />
      ) : (
        <>
          {labeldisclaimer ? (
            <div className="labeldisclaimer">{labeldisclaimer}</div>
          ) : null}
          <Input
            placeholder={placeholder}
            prefix={prefixIcon()}
            onChange={disabledOnChange()}
            onBlur={input.onBlur}
            id={idComponent}
            value={input.value}
            disabled={disabled}
            className={className}
            maxLength={maxlength}
            allowClear={allowClear}
            labeldisclaimer={labeldisclaimer}
          />
        </>
      )}
      <p>{additionalCaption()}</p>
    </Form.Item>
  );
};

export const TextInput = ({
  input,
  meta,
  placeholder,
  idComponent,
  disabled,
  className,
}: IPropsText) => {
  const handleOnChange = e => input.onChange(e.target.value);
  return (
    <Input
      placeholder={placeholder}
      onChange={handleOnChange}
      onBlur={input.onBlur}
      id={idComponent}
      value={input.value}
      disabled={disabled}
      className={className}
    />
  );
};

export const TextNumberInput = ({
  input,
  placeholder,
  idComponent,
  icon,
  min,
  max,
  defaultValue,
  disabled,
  className,
}: IPropsTextNumber) => {
  return (
    <InputNumber
      style={{ width: 60, marginLeft: 3 }}
      placeholder={placeholder}
      prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
      id={idComponent}
      min={min}
      max={max}
      defaultValue={defaultValue}
      disabled={disabled}
      className={className}
      {...input}
    />
  );
};

export const InputTextNumber = ({
  input,
  placeholder,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  min,
  max,
  defaultValue,
  disabled,
  className,
  labelIsRequired,
  description,
  addtionalOnChange,
}: IPropsTextNumber) => {
  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
      {lebelRequired()}
    </span>
  ) : null;
  const handleOnchange = e => {
    input.onChange(e);
    if (addtionalOnChange) {
      addtionalOnChange(e);
    }
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Space>
        <InputNumber
          style={defaultStyleInput}
          placeholder={placeholder}
          id={idComponent}
          min={min}
          max={max}
          defaultValue={defaultValue}
          disabled={disabled}
          className={className}
          onChange={handleOnchange}
          value={input.value}
          onBlur={input.onBlur}
        />
        <span>{description}</span>
      </Space>
    </Form.Item>
  );
};

export const InputSwitch = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
  additionalOnChange,
}: IProps) => {
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  const handleOnChange = e => {
    input.onChange(e);
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Switch
        defaultChecked={false}
        id={idComponent}
        disabled={disabled}
        checked={input.value === '' ? false : input.value}
        onChange={additionalOnChange ? additionalOnChange : handleOnChange}
      />
    </Form.Item>
  );
};

export const InputSelect = ({
  input,
  meta: { touched, error },
  data,
  defaultItemName,
  idComponent,
  label,
  additionalHandleChangeFunc,
  disabled,
  combineValue,
  disabledByKey,
  disabledByKeyNegation,
  className,
  formClassName,
  customOption,
  addtionalLabel,
  showSearch,
  labelIsRequired,
  placeholder,
  handleSearch,
  loading,
}: IPropsSelect) => {
  const items = data.map((item, key) => {
    const keyNavigation =
      disabledByKeyNegation === false
        ? item[disabledByKey]
        : !item[disabledByKey];
    return (
      <Select.Option
        id={`optionValue${label}-${key}`}
        key={`${item.id}${key}`}
        value={item.id}
        disabled={disabledByKey ? keyNavigation : false}
      >
        {combineValue
          ? `${item[combineValue[1]]} ${combineValue[0]} ${
              item[combineValue[2]]
            }`
          : item.name}
      </Select.Option>
    );
  });

  const optionLabelOnly = () => {
    return (
      <Select.Option value={input.value}>
        {input.value.split('|')[0]}
      </Select.Option>
    );
  };

  const optionLabelAndCode = () => {
    if (input.value) {
      return (
        <Select.Option value={input.value}>
          {`${input.value.split('|')[1]} - ${input.value.split('|')[0]}`}
        </Select.Option>
      );
    }
    return null;
  };
  const [count, setcount] = useState(0);

  const handleOnChange = e => {
    input.onChange(e);
    setcount(count + 1);
  };
  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };
  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
        {lebelRequired()}
      </span>
    </span>
  );
  const valueSearch = showSearch ? showSearch : false;
  const optionLabel = () => {
    if (customOption === 'labelOnly') {
      return optionLabelOnly();
    } else if (customOption === 'labelAndCode') {
      return optionLabelAndCode();
    } else {
      return items;
    }
  };
  return (
    <Form.Item
      validateStatus={
        (idComponent?.includes('Predecessor') && error !== undefined) ||
        ((count > 0 || input.value) && error !== undefined)
          ? 'error'
          : ''
      }
      help={
        (idComponent?.includes('Predecessor') && error !== undefined) ||
        ((count > 0 || input.value) && error !== undefined)
          ? error
          : ''
      }
      label={labelTitle}
      colon={false}
      id={idComponent}
      className={formClassName}
    >
      <Select
        showSearch={valueSearch}
        onChange={handleOnChange}
        onSelect={additionalHandleChangeFunc}
        onSearch={handleSearch}
        id={idComponent}
        value={input.value}
        style={defaultStyleInput}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        loading={loading}
      >
        <Select.Option value="">{defaultItemName}</Select.Option>
        {optionLabel()}
      </Select>
    </Form.Item>
  );
};

export const InputSelectWithoutForm = ({
  input,
  idComponent,
  defaultItemName,
  data,
  additionalHandleChangeFunc,
  disabled,
  className,
}: IPropsSelect) => {
  const handleOnchange = e => {
    input.onChange(e);
  };
  const items = data.map((item, index) => {
    return (
      <Select.Option
        id={`optionValue${item}-${index}`}
        key={`${item.id}${index}`}
        value={item.id}
      >
        {item.name}
      </Select.Option>
    );
  });
  return (
    <Select
      onSelect={additionalHandleChangeFunc}
      id={idComponent}
      disabled={disabled}
      value={input?.value}
      onChange={handleOnchange}
      className={className}
    >
      <Select.Option value="">{defaultItemName}</Select.Option>
      {items}
    </Select>
  );
};

export const InputPassword = ({
  input,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  placeholder,
  disabled,
}: IPropsText) => {
  const handleOnChange = e => input.onChange(e.target.value);
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Input.Password
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        placeholder={placeholder}
        className="formInputLogin"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export const InputTimePicker = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
}) => {
  const handleOnChange = e => input.onChange(e);
  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
    </span>
  );
  return (
    <Form.Item
      validateStatus={
        touched && error !== undefined ? (disabled ? '' : 'error') : ''
      }
      help={touched && error !== undefined ? (disabled ? '' : error) : ''}
      label={labelTitle}
      colon={false}
    >
      <TimePicker
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value !== '' ? moment(input.value, 'HH:mm:ss') : undefined}
        disabled={disabled}
      />
    </Form.Item>
  );
};
