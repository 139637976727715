import moment from 'moment';
import instance from '../Services/interceptor';
import { IResultCandidateGroupDao } from '../Modules/QAProcess/DAO/qaProcess.dao';

export const getQAListAPI = async () => {
  const { data } = await instance.get(`/Candidate/workflow/group/DOSDQA`);
  return data;
};

export const getSchemaFormAPI = async () => {
  const { data } = await instance.get(`/form?Code=FRMCR0001`);
  return data;
};

export const getCandidateDetailAPI = async (params: any) => {
  const code = params.queryKey[1];
  const { data } = await instance.get(`/Candidate?Code=${code}`);
  return data;
};

export const getLookupRecruiterAPI = async () => {
  const { data } = await instance.get(`/Lookup/configuration/agentCode`);
  const newData: any = [];
  for (const iterator of data) {
    const element: any = iterator;
    element.id = `${iterator.agentName}|${iterator.agentCode}`;
    element.name = `${iterator.agentCode} - ${iterator.agentName}`;
    newData.push(element);
  }
  return newData;
};

export const getLookupDirectLeaderAPI = async (params: any) => {
  const { agentCode, candidateLevel } = params.queryKey[1];
  const { data } = await instance.get(
    `/Candidate/lookup/approver/${agentCode}/${candidateLevel}`
  );

  const newData: any = [];
  if (candidateLevel !== 'A4' && data.agentName) {
    for (const iterator of data.list) {
      if (iterator.agentCode === data.agentCode) {
        newData.push({
          agencyCode: data.agencyCode,
          agentCode: data.agentCode,
          agentEmail: data.agentEmail,
          agentLevel: iterator.agentLevel,
          agentName: data.agentName,
          descriptions: iterator.descriptions,
          id: `${data.agentName}|${data.agentCode}`,
          name: `${data.agentCode} - ${data.agentName} - ${iterator.descriptions}`,
        });
      } else {
        const element: any = iterator;
        element.id = `${iterator.agentName}|${iterator.agentCode}`;
        element.name = `${iterator.agentCode} - ${iterator.agentName} - ${iterator.descriptions}`;
        newData.push(element);
      }
    }
  }
  return newData;
};

export const getLookupSalesOfficeAPI = async () => {
  const { data } = await instance.get(`/Lookup/configuration/salesOffice`);
  const newData: any = [];
  for (const iterator of data) {
    newData.push({
      id: `${iterator.gaOfficeName}|${iterator.gaOfficeCode}`,
      name: `${iterator.gaOfficeCode} - ${iterator.gaOfficeName}`,
    });
  }
  return newData;
};

export const getLookupAgencyAPI = async () => {
  const { data } = await instance.get(`/Lookup/configuration/agencyCode`);
  const newData: any = [];
  for (const iterator of data) {
    newData.push({
      id: `${iterator.agencyName}|${iterator.agencyCode}`,
      name: `${iterator.agencyCode} - ${iterator.agencyName}`,
    });
  }
  return newData;
};

export const getLookupBankAPI = async () => {
  const { data } = await instance.get(`/Master/bank`);
  return data;
};

export const getListRegionAPI = async (params: any) => {
  const { regionName, idRegion, fromPostalCode, dynamicForm } =
    params.queryKey[1];

  const { data } = await instance.get(`/${regionName}/${idRegion}`);
  const newData: any = [];
  if (dynamicForm) {
    for (const iterator of data) {
      if (fromPostalCode) {
        newData.push({
          id: `${iterator.name}|${iterator.id}|${iterator.postalCode}`,
          name: iterator.name,
        });
      } else {
        newData.push({
          id: `${iterator.name}|${iterator.id}`,
          name: iterator.name,
        });
      }
    }
  }
  const regionData = dynamicForm ? newData : data;
  return regionData;
};

export const getPlaceOfBirthListAPI = async (params: any) => {
  const { formName, regionName } = params.queryKey[1];
  const { data } = await instance.get(`/city`);
  const newData: any = [];
  for (const iterator of data) {
    newData.push({
      id: iterator.name,
      name: iterator.name,
    });
  }

  return { newData, formName, regionName };
};

export const getReligionListAPI = async () => {
  const { data } = await instance.get(`/Master/religion`);
  return data;
};

export const getLastEducationListAPI = async () => {
  const { data } = await instance.get(`/Master/education`);
  return data;
};

export const getDocAgreementListAPI = async (params: any) => {
  const { candidateLevel } = params?.queryKey[1];
  const { data } = await instance.get(
    `/Configuration/disclaimer/module/mapping?menuType=Agreement&agreementType=${
      candidateLevel === 'FC' ? 'NonLeader' : 'Leader'
    }`
  );
  return data;
};

export const getCitySubmissionAPI = async (params: any) => {
  const { idProvince } = params.queryKey[1];
  const { data } = await instance.get(`/city/${idProvince}`);
  return data;
};

export const getPendingRemarksAPI = async () => {
  const { data } = await instance.get(`/Pending/remark`);
  return data;
};

export const getDetailSubmissionAPI = async (
  params: any
): Promise<{
  dataSubmission: IResultCandidateGroupDao;
  documentContentData: any;
  listDL: any;
  listCity: any;
  listdistrict: any;
  listsubdistrict: any;
  listCityDomicile: any;
  listdistrictDomicile: any;
  listsubdistrictdomicile: any;
  listbeneficiary: any;
  listworkexperience: any;
  aajiSubmission: any;
  listAttachment: any;
}> => {
  let listIdDocument: any;
  let listAttachment: any;
  const { caseId, taskId, detailCandidate } = params.queryKey[1];
  let aajiSubmission: any;
  if (detailCandidate?.candidateStatus === 'AAJI Screening') {
    const { data } = await instance.get(
      `/Pending/remark/world/check?applicationNumber=${detailCandidate.contractNumber}`
    );
    aajiSubmission = data;
  }

  const { data: dataSubmission }: { data: IResultCandidateGroupDao } =
    await instance.get(
      `/Candidate/workflow/group/detail?caseId=${caseId}&taskId=${taskId}`
    );

  const convertLevel = (desclevel: any) => {
    if (desclevel === 'FC') {
      return 'A1';
    } else if (desclevel === 'AM') {
      return 'A2';
    } else if (desclevel === 'SAM') {
      return 'A3';
    } else {
      return 'A4';
    }
  };
  const agency = dataSubmission.submission.personaldata.agency;
  const agentCode = agency.recruiter.split('|')[1];
  const candidateLevel = convertLevel(agency.candidateLevel);
  const { data } = await instance.get(
    `/Candidate/lookup/approver/${agentCode}/${candidateLevel}`
  );
  const listDL: any = [];
  if (candidateLevel !== 'A4' && data.agentName) {
    for (const iterator of data.list) {
      if (iterator.agentCode === data.agentCode) {
        listDL.push({
          agencyCode: data.agencyCode,
          agentCode: data.agentCode,
          agentEmail: data.agentEmail,
          agentLevel: iterator.agentLevel,
          agentName: data.agentName,
          descriptions: iterator.descriptions,
          id: `${data.agentName}|${data.agentCode}`,
          name: `${data.agentCode} - ${data.agentName} - ${iterator.descriptions}`,
        });
      } else {
        const element: any = iterator;
        element.id = `${iterator.agentName}|${iterator.agentCode}`;
        element.name = `${iterator.agentCode} - ${iterator.agentName} - ${iterator.descriptions}`;
        listDL.push(element);
      }
    }
  }

  const addresEducation =
    dataSubmission.submission.personaldata.addresseducation;
  const idProvince = addresEducation.province.split('|')[1];
  const { data: city } = await instance.get(`/city/${idProvince}`);

  const listCity: any = [];
  for (const iterator of city) {
    listCity.push({
      id: `${iterator.name}|${iterator.id}`,
      name: iterator.name,
    });
  }

  const idCity = addresEducation.city.split('|')[1];
  const { data: district } = await instance.get(`/district/${idCity}`);
  const listdistrict: any = [];
  for (const iterator of district) {
    listdistrict.push({
      id: `${iterator.name}|${iterator.id}`,
      name: iterator.name,
    });
  }

  const idDistrict = addresEducation.district.split('|')[1];
  const { data: subdistrict } = await instance.get(`/village/${idDistrict}`);
  const listsubdistrict: any = [];
  for (const iterator of subdistrict) {
    listsubdistrict.push({
      id: `${iterator.name}|${iterator.id}|${iterator.postalCode}`,
      name: iterator.name,
    });
  }

  const idProvinceDomicile = addresEducation.provincedomicile.split('|')[1];
  const { data: cityDomicile } = await instance.get(
    `/city/${idProvinceDomicile}`
  );
  const listCityDomicile: any = [];
  for (const iterator of cityDomicile) {
    listCityDomicile.push({
      id: `${iterator.name}|${iterator.id}`,
      name: iterator.name,
    });
  }

  const idCityDomicile = addresEducation.citydomicile.split('|')[1];
  const { data: districtDomicile } = await instance.get(
    `/district/${idCityDomicile}`
  );
  const listdistrictDomicile: any = [];
  for (const iterator of districtDomicile) {
    listdistrictDomicile.push({
      id: `${iterator.name}|${iterator.id}`,
      name: iterator.name,
    });
  }

  const idDistrictDomicile = addresEducation.districtdomicile.split('|')[1];
  const { data: subdistrictDomicile } = await instance.get(
    `/village/${idDistrictDomicile}`
  );
  const listsubdistrictdomicile: any = [];
  for (const iterator of subdistrictDomicile) {
    listsubdistrictdomicile.push({
      id: `${iterator.name}|${iterator.id}|${iterator.postalCode}`,
      name: iterator.name,
    });
  }
  let listbeneficiary: any = [];
  if (
    dataSubmission.submission.beneficiary &&
    dataSubmission.submission.beneficiary.list.values
  ) {
    const beneficiary = dataSubmission.submission.beneficiary.list.values;
    for (let index = 0; index < beneficiary.length; index++) {
      const element: any = beneficiary[index];
      element.id = `beneficiary_${index}`;
      listbeneficiary.push(element);
    }
  }

  let listworkexperience: any = [];
  if (
    dataSubmission.submission.workexperience &&
    dataSubmission.submission.workexperience.list.values
  ) {
    const workExperience = dataSubmission.submission.workexperience.list.values;
    for (let index = 0; index < workExperience.length; index++) {
      const element = workExperience[index];
      listworkexperience.push(element);
    }
  }

  const listDocument: any = [];
  const valueDocument = Object.values(dataSubmission.submission.documents);
  const keyDocument = Object.keys(dataSubmission.submission.documents);
  keyDocument.forEach((element, index) => {
    listDocument.push({ title: element, idContent: valueDocument[index] });
  });
  listDocument.push({
    title: 'idcard',
    idContent: dataSubmission.submission.personaldata.profile.idcard,
  });
  listDocument.push({
    title: 'signature',
    idContent: dataSubmission.submission.useragreements.list.signature,
  });

  const content: any = {};
  for (const iterator of listDocument) {
    const { idContent, title } = iterator;
    if (idContent) {
      if (title === 'otherattachments') {
        listIdDocument = idContent.split('|');
        for (let index = 0; index < listIdDocument.length; index++) {
          const element = listIdDocument[index];
          listAttachment = listIdDocument.filter(val => {
            return val !== '[object Object]';
          });
          if (element !== '[object Object]') {
            const { data: docContent } = await instance.get(
              `/Content/GetContent?contentId=${element}`
            );
            const detail = docContent;
            const newData: any = detail;
            newData.content = `data:${detail.properties.mime};base64, ${detail.content}`;
            content[`otherattachments${index}`] = newData;

            dataSubmission.submission.documents[`otherattachments${index}`] =
              element;
          }
        }
      } else {
        const listIdContent =
          typeof idContent === 'string' ? idContent?.split('|') : '';
        if (listIdContent) {
          const { data: docContent } = await instance.get(
            `/Content/GetContent?contentId=${
              listIdContent ? listIdContent?.[0] : ''
            }`
          );
          const detail = docContent;
          const newData: any = detail;
          newData.content = `data:${detail.properties.mime};base64, ${detail.content}`;
          content[title] = newData;
        }
      }
    }
  }

  return {
    aajiSubmission,
    dataSubmission,
    listDL,
    documentContentData: content,
    listCity,
    listdistrict,
    listsubdistrict,
    listCityDomicile,
    listdistrictDomicile,
    listsubdistrictdomicile,
    listAttachment,
    listbeneficiary,
    listworkexperience,
  };
};

export const downloadAAJICheckingAPI = async (params: any) => {
  await instance
    .get(
      `/Candidate/report/aaji/checking/xlsx?candidateGroup=DOSDQA&candidateVariable=AAJIScreening`,
      {
        responseType: 'blob',
      }
    )
    .then(response => {
      const DateNow = moment().format('DDMMYYYY');
      const fileName = `AAJI-Checking-${DateNow}.xlsx`;
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
};

export const submitQAProcessAPI = async (params: any) => {
  const { payloadLead, submission, documents } = params;
  const { data: addLead } = await instance.post(`/Lead`, [payloadLead.addLead]);

  const payloadPromoteLead: any = payloadLead.promoteLead;
  payloadPromoteLead.id = addLead[0];

  const { data: promoteLead } = await instance.post(
    `/Lead/promote`,
    payloadPromoteLead
  );
  const candidateCode = promoteLead.split(' ')[3];
  const listValueDocument = Object.values(documents);
  for (const iterator of listValueDocument) {
    const data: any = iterator;
    const payload: any = {};
    payload.contentId = data.id;
    const newProperties = data.properties;
    newProperties.candidateCode = candidateCode;
    payload.properties = newProperties;
    await instance.put(`/Content/UpdateContentProperty`, payload);
  }

  await instance.post(`/Candidate/workflow/submit/registration`, {
    candidateCode,
    caseTemplateId: 'MZA_Candidate_Recruitment_Web',
    isAdira: false,
    submission,
  });
};

export const submitEditQAProcessAPI = async (params: any) => {
  const {
    payloadLead,
    detailCandidate,
    formValue,
    listbeneficiary,
    listworkexperience,
    totalOtherDocument,
  } = params;
  const payload: any = formValue;
  if (formValue.personaldata.profile.mobilephone) {
    payload.personaldata.profile.mobilephone = `+62${formValue.personaldata.profile.mobilephone}`;
  }
  if (formValue.personaldata.profile.homephone) {
    payload.personaldata.profile.homephone = `+62${formValue.personaldata.profile.homephone}`;
  } else {
    payload.personaldata.profile.homephone = '';
  }
  if (formValue.personaldata.profile.workphone) {
    payload.personaldata.profile.workphone = `+62${formValue.personaldata.profile.workphone}`;
  } else {
    payload.personaldata.profile.workphone = '';
  }
  for (const iterator of listbeneficiary) {
    delete iterator.id;
  }
  for (const iterator of listworkexperience) {
    delete iterator.id;
  }
  payload.beneficiary.list.values = listbeneficiary;
  payload.workexperience.list.values = listworkexperience;
  if (formValue && formValue.domicileaddress !== undefined) {
    delete payload.domicileaddress;
  }

  const listOtherDocument: any = [];
  for (let index = 0; index < totalOtherDocument; index++) {
    if (payload.documents && payload.documents[`otherattachments${index}`]) {
      listOtherDocument.push(payload.documents[`otherattachments${index}`]);
      delete payload.documents[`otherattachments${index}`];
    }
  }
  const joinArray = listOtherDocument.join('|');
  payload.documents.otherattachments = joinArray;

  if (detailCandidate && payload) {
    detailCandidate.Agency = payload.personaldata.agency.agency.split('|')[0];
    detailCandidate.candidateLevel = payload.personaldata.agency.candidateLevel;
    detailCandidate.recruiter =
      payload.personaldata.agency.recruiter.split('|')[0];
  }

  await instance.put(`/Candidate/workflow/registration`, {
    candidateCode: detailCandidate.code,
    submission: payload,
    candidateApprovers: payloadLead.promoteLead.candidateApprovers,
  });
};

export const mutateRejectCandidateAPI = async (params: any) => {
  const { detailCandidate, reason, username } = params;
  const caseId = detailCandidate.caseId;
  const taskId = detailCandidate.taskId;

  return await instance.post(`/Candidate/workflow/approve/registration`, {
    caseId,
    taskId,
    candidateLevel: detailCandidate.candidateLevel,
    variables: {
      approver: {
        value: username,
      },
      approve: {
        value: false,
      },
      candidateLevel: {
        value: detailCandidate.candidateLevel,
      },
      reason: {
        value: reason,
      },
    },
  });
};

export const checkValidationAPI = async (params: any) => {
  const { idNumber, email, phoneNumber, code } = params;
  const mobilephone = `+62${phoneNumber}`;
  const { data } = await instance.post(`/Lead/check/validation`, {
    idNumber,
    email,
    phoneNumber: mobilephone,
    code,
  });
  return data;
};
