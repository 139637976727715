import { Button, Col, Image, Row, Skeleton } from 'antd';
import { FormApi } from 'final-form';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import HasPermission from '../../../../../App/HasPermission';
import { formatBytes } from '../../../../../App/Helper';
import CButton from '../../../../../Assets/Components/CButton';
import CIcon from '../../../../../Assets/Components/CIcon';
import {
  InputSwitch,
  InputText,
  InputTextNumber,
} from '../../../../../Assets/Components/CInput';
import CModalRFF from '../../../../../Assets/Components/CModalRFF';
import RadioButtonIconRFF from '../../../../../Assets/Components/CRadioRFF';
import InputFile from '../../../../../Assets/Components/InputFile';
import slideValidation from '../../../Validation/contentSlideValidation';

interface IProps {
  isShow: boolean;
  modalAction: string;
  editMode: boolean;
  isLoading: any;
  intl: any;
  handleCancel: () => void;
  handleDeleteFile: (
    type: 'file' | 'cover',
    form: FormApi<any, Partial<any>>,
    fieldName: string
  ) => void;
  handleUpload: (
    type: 'file' | 'cover',
    values: any,
    form: FormApi<any, Partial<any>>,
    fileType: string
  ) => void;
  handleChangeFileType: () => void;
  handleSubmit: (values: any) => void;
  slideFileProperties: any;
  setSlideFileProperties: React.Dispatch<React.SetStateAction<any>>;
  slideCoverProperties: any;
  setSlideCoverProperties: React.Dispatch<React.SetStateAction<any>>;
  slideFileSize: number;
  initialValues?: any;
}

function SlideModalComponent(props: IProps) {
  const {
    isShow,
    intl,
    isLoading,
    handleCancel,
    handleDeleteFile,
    handleUpload,
    handleChangeFileType,
    handleSubmit,
    modalAction,
    editMode,
    initialValues,
    slideFileProperties,
    slideCoverProperties,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailElearningContentSlide" />
        ) : (
          <FormattedMessage id="addElearningContentSlide" />
        )}
      </h3>
    );
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') return 'Create';
      else return 'Edit';
    }
    return 'Save Change';
  };
  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { invalid } = propsFooter;
    if (HasPermission('u') || HasPermission('c')) {
      return (
        <Button
          className={
            editMode
              ? invalid
                ? 'btnSubmitDisabled'
                : 'btnSubmit'
              : 'btnSubmit'
          }
          icon={<CIcon type="CheckOutlined" />}
          id="btnSubmit"
          htmlType="submit"
          disabled={modalAction === 'update' && !editMode ? false : invalid}
        >
          {buttonContent(modalAction, editMode)}
        </Button>
      );
    } else return null;
  };

  const buttonData = [
    {
      type: 'ghost',
      icon: <CIcon type="YoutubeFilled" className="btnRadioIcon" />,
      name: 'Video',
      id: 'video',
      className: 'btnRadioVideo',
    },
    {
      type: 'ghost',
      icon: <CIcon type="AudioFilled" className="btnRadioIcon" />,
      name: 'Podcast (Audio)',
      id: 'audio',
      className: 'btnRadioAudio',
    },
    {
      type: 'ghost',
      icon: <CIcon type="FilePdfFilled" className="btnRadioIcon" />,
      name: 'PDF',
      id: 'pdf',
      className: 'btnRadioPdf',
    },
    {
      type: 'ghost',
      icon: <CIcon type="CodeFilled" className="btnRadioIcon" />,
      name: 'Video (Embed Link)',
      id: 'youtube',
      className: 'btnRadioEmbed',
    },
  ];

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { form, values } = propsContent;

    const handleFileTypes = (type: string) => {
      const fileTypes: any = [];
      if (type === 'video') fileTypes.push('.mp4');
      else if (type === 'audio') fileTypes.push('.mp3');
      else if (type === 'pdf') fileTypes.push('.pdf');

      return fileTypes;
    };

    const disabled =
      editMode === false && modalAction === 'update' ? true : false;

    const dataButton = [
      {
        type: 'primary',
        id: 'btnDeleteImage',
        onClick: () => handleDeleteFile('file', form, values?.fileType),
        className: 'btnDeleteContentSlide',
        icon: 'DeleteOutlined',
        disabled,
      },
    ];
    const dataButtonCover = [
      {
        type: 'primary',
        id: 'btnDeleteImageCover',
        onClick: () => handleDeleteFile('cover', form, values?.fileType),
        className: 'btnDeleteContentSlide',
        icon: 'DeleteOutlined',
        disabled,
      },
    ];

    const handleFrameSrc = () => {
      let embedId;
      if (values?.embedLink) {
        const srcToArray = values?.embedLink.split('/');
        embedId = srcToArray[srcToArray.length - 1].replace('watch?v=', '');
      }
      const src = `https://www.youtube.com/embed/${embedId}`;
      return src;
    };
    const renderUploadFile = () => {
      if (values?.fileType === 'video' || values?.fileType === 'audio') {
        return (
          <div>
            <p className="TitleUploadPrivy">
              Cover {values?.fileType === 'video' ? '(Opsional)' : ''}
            </p>
            {slideCoverProperties ? (
              <Row>
                <Col span={8}>
                  <Image
                    src={
                      slideCoverProperties
                        ? slideCoverProperties.direct_url
                        : null
                    }
                    width={'100%'}
                  />
                </Col>
                <Col span={16}>
                  <div className="containerFileInformation">
                    <div>
                      <b className="fileNameInformation">
                        {slideCoverProperties
                          ? slideCoverProperties.name
                          : null}
                      </b>
                      <br />
                      {formatBytes(
                        slideCoverProperties ? slideCoverProperties.size : 0
                      )}
                    </div>
                    <CButton buttonData={dataButtonCover} />
                  </div>
                </Col>
              </Row>
            ) : (
              <div>
                <Field
                  name={`${values?.fileType}Cover`}
                  component={InputFile}
                  idComponent="uppyUploadSlideCover"
                  uppyConfig={{
                    minFile: 1,
                    maxFile: 1,
                    allowedFile: ['.jpg', '.jpeg'],
                    maxSize: 5242880,
                  }}
                  useFile={true}
                  height={300}
                />
                <p
                  className={
                    values?.fileType === 'audio'
                      ? 'uploadFormatRequired'
                      : 'uploadFormat'
                  }
                >
                  .jpg / jpeg format
                </p>
                <Button
                  type="primary"
                  onClick={() =>
                    handleUpload(
                      'cover',
                      values?.[`${values.fileType}Cover`][0],
                      form,
                      values.fileType
                    )
                  }
                  id="btnUpload"
                  disabled={
                    !values[`${values.fileType}Cover`] ||
                    values[`${values.fileType}Cover`].length === 0
                      ? true
                      : false
                  }
                  loading={isLoading}
                  className="btnRadius10"
                >
                  Upload
                </Button>
              </div>
            )}
            <p className="TitleUploadPrivy">Content</p>
            {slideFileProperties || initialValues?.file ? (
              <Row>
                <Col span={8}>
                  <iframe
                    width="100%"
                    title={
                      slideFileProperties ? slideFileProperties.name : null
                    }
                    src={
                      slideFileProperties ? slideFileProperties?.direct_url : ''
                    }
                    frameBorder="0"
                    allowFullScreen
                  />
                </Col>
                <Col span={16}>
                  <div className="containerFileInformation">
                    <div>
                      <b className="fileNameInformation">
                        {slideFileProperties ? slideFileProperties.name : null}
                      </b>
                      <br />
                      {formatBytes(
                        slideFileProperties ? slideFileProperties.size : 0
                      )}
                    </div>
                    <CButton buttonData={dataButton} />
                  </div>
                </Col>
              </Row>
            ) : (
              <div>
                <Field
                  name={`${values?.fileType}`}
                  component={InputFile}
                  idComponent="uppyUploadSlide"
                  uppyConfig={{
                    minFile: 1,
                    maxFile: 1,
                    allowedFile: handleFileTypes(values?.fileType),
                    maxSize:
                      values?.fileType === 'video' ? 209715200 : 10485760,
                  }}
                  useFile={true}
                  height={300}
                />
                <p className="uploadFormatRequired">
                  {values?.fileType === 'video' ? '.mp4' : '.mp3'} format
                </p>
                <Button
                  type="primary"
                  onClick={() =>
                    handleUpload(
                      'file',
                      values?.[values.fileType][0],
                      form,
                      values.fileType
                    )
                  }
                  id="btnUpload"
                  disabled={
                    !values[values.fileType] ||
                    values[values.fileType].length === 0
                      ? true
                      : false
                  }
                  loading={isLoading}
                  className="btnRadius10"
                >
                  Upload
                </Button>
              </div>
            )}
          </div>
        );
      } else if (values?.fileType === 'pdf') {
        if (
          slideFileProperties ||
          (initialValues?.file && initialValues?.fileType === 'pdf')
        ) {
          return (
            <Skeleton active loading={isLoading}>
              <Row>
                <Col span={24} style={{ width: '100%' }}>
                  <iframe
                    id="iframe"
                    title={
                      slideFileProperties ? slideFileProperties.name : null
                    }
                    src={
                      slideFileProperties
                        ? slideFileProperties.file_url
                        : undefined
                    }
                    width="100%"
                    height="300"
                  />
                  <div className="containerTitle">
                    <div className="containerDetail">
                      <b className="fileNameInformation">
                        {slideFileProperties ? slideFileProperties.name : null}
                      </b>
                      <br />
                      {formatBytes(
                        slideFileProperties ? slideFileProperties.size : null
                      )}
                    </div>
                    <CButton buttonData={dataButton} />
                  </div>
                </Col>
              </Row>
            </Skeleton>
          );
        } else {
          return (
            <div>
              <Field
                name={`${values?.fileType}`}
                component={InputFile}
                idComponent="uppyUploadSlide"
                uppyConfig={{
                  minFile: 1,
                  maxFile: 1,
                  allowedFile: handleFileTypes(values?.fileType),
                  maxSize: values?.fileType === 'video' ? 209715200 : 10485760,
                }}
                useFile={true}
                height={300}
              />
              <p className="uploadFormatRequired">.pdf format</p>
              <Button
                type="primary"
                onClick={() =>
                  handleUpload(
                    'file',
                    values?.[values.fileType][0],
                    form,
                    values.fileType
                  )
                }
                id="btnUpload"
                disabled={
                  !values[values.fileType] ||
                  values[values.fileType].length === 0
                    ? true
                    : false
                }
                loading={isLoading}
                className="btnRadius10"
              >
                Upload
              </Button>
            </div>
          );
        }
      } else if (values?.fileType === 'youtube') {
        return (
          <div>
            <Field
              name="embedLink"
              component={InputText}
              idComponent="inputContentSlideEmbedLink"
              placeholder={intl.formatMessage({
                id: 'placeholderSlideEmbedLink',
              })}
              disabled={disabled}
            />
            <p className="embedLinkDesc">Link Youtube</p>
            <iframe
              width="100%"
              height="300"
              src={handleFrameSrc()}
              title="Embed link player"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        );
      }
      return null;
    };

    return (
      <React.Fragment>
        <Field
          name="name"
          component={InputText}
          label={intl.formatMessage({ id: 'ElearningSlideName' })}
          idComponent="inputContentSlideName"
          placeholder={intl.formatMessage({
            id: 'placeHolderSlideName',
          })}
          disabled={disabled}
        />
        <Field
          name="sequence"
          component={InputTextNumber}
          label={intl.formatMessage({ id: 'ElearningSlideSequence' })}
          idComponent="inputModuleSequence"
          placeholder={intl.formatMessage({
            id: 'placeHolderSlideSequence',
          })}
          min={1}
          disabled={disabled}
        />
        <Field
          name="isActive"
          idComponent="inputStatus"
          component={InputSwitch}
          label={intl.formatMessage({ id: 'ElearningSlideStatus' })}
          disabled={disabled}
        />
        <Field
          name="fileType"
          component={RadioButtonIconRFF}
          idComponent="radioFileType"
          dataOption={buttonData}
          className="RadioFileType"
          label="Select Type"
          form={form}
          disabled={disabled}
          initialValues={initialValues}
          addtionalFunction={handleChangeFileType}
        />
        {renderUploadFile()}
      </React.Fragment>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={(val: any) =>
        slideValidation(val, slideCoverProperties)
      }
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

export default injectIntl(SlideModalComponent);
