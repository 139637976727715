import CButton from '../../../../Assets/Components/CButton';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { FormRenderProps } from 'react-final-form';
import React from 'react';
import { Steps } from 'antd';
import { classGeneralValidation } from '../../Validation/ClassValidation';

interface IProps {
  modalAction: string;
  currentSteps: number;
  dataClassNonSelf?: any;
  disabledTabs: (index: number, steps: number) => boolean;
  editMode: boolean;
  handleBack: () => void;
  handleCancel: () => void;
  handleEditClick: () => void;
  handleNext: () => void;
  handleStep?: any;
  initialValues: any;
  isLoading: boolean;
  onSubmit: (val: any) => void;
  selectedAgentManual: any[];
  steps?: any;
}

const { Step } = Steps;

export default function ClassModalComponent(props: IProps) {
  const {
    modalAction,
    editMode,
    handleCancel,
    handleEditClick,
    onSubmit,
    selectedAgentManual,
    initialValues,
    steps,
    currentSteps,
    handleStep,
    isLoading,
  } = props;

  const renderTitle = () => {
    if (modalAction === 'register' && !isLoading) {
      return 'Class Baru';
    } else {
      return 'Edit Class';
    }
  };
  const headerComponent = () => {
    return <h3 className="headerTitle capital">{renderTitle()}</h3>;
  };

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { form, errors, values } = propsContent;
    return (
      <div>
        <div className="containerStepNonClassELearning">
          <Steps
            labelPlacement="vertical"
            className="stepsNonClassELearning"
            current={currentSteps}
            onChange={handleStep}
          >
            {steps.map(step => (
              <Step
                key={step.title}
                title={step.title}
                disabled={step.disabled}
              />
            ))}
          </Steps>
        </div>
        <div className="containerContentStepSubModul">
          {React.cloneElement(steps[currentSteps].content, {
            form,
            formError: errors,
            formValues: values,
          })}
        </div>
      </div>
    );
  };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { invalid, values } = propsFooter;
    const dataButtonModalSubModule = [
      modalAction === 'update' && !editMode
        ? {
            type: 'primary',
            content: 'Edit',
            id: 'btnEditForm',
            className: 'btnEditRegsitration',
            icon: 'EditOutlined',
            onClick: handleEditClick,
          }
        : null,
      !(modalAction === 'update' && editMode === false)
        ? {
            type: 'primary',
            content: modalAction !== 'update' ? 'Create' : 'Simpan',
            id: 'btnSaveForm',
            className: 'btnSaveSubmodule',
            onClick: () => onSubmit(values),
            disabled: invalid,
            isLoading,
          }
        : null,
    ];

    return (
      <div className="ContainerButtonSubModule">
        <CButton buttonData={dataButtonModalSubModule} isLoading={isLoading} />
      </div>
    );
  };

  return (
    <CModalRFF
      modalIsShow={true}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={vals =>
        classGeneralValidation(vals, modalAction, selectedAgentManual)
      }
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}
