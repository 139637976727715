import { FormApi } from 'final-form';
import React, { useEffect } from 'react';
import ModuleModalGeneralComponent from '../../Component/Modal/ModuleModalGeneralComponent';

interface IProps {
  certificateList: any[];
  disabled: boolean;
  modalAction: string;
  predecessorList: any[];
  selectedData: any;
  form?: FormApi<any, Partial<any>>;
  formError?: any;
  formValues?: any;
}

export default function ModuleModalGeneralContainer(props: IProps) {
  const { predecessorList, form, formValues } = props;

  useEffect(
    () => {
      if (!formValues) return;

      if (!formValues?.general?.isCertificate)
        form?.change('general.certificateId', null);
    },
    // eslint-disable-next-line
    [formValues?.general?.isCertificate]
  );

  useEffect(
    () => {
      if (!formValues) return;

      if (!formValues?.general?.isCpdStatus) {
        form?.change('general.cpdRefreshName', null);
        form?.change('general.cpdPoint', null);
      }
    },
    // eslint-disable-next-line
    [formValues?.general?.isCpdStatus]
  );

  const handleAddtionalSelectSequence = (e: any) => {
    const data = predecessorList.find(item => item.sequence === e);
    form?.change('general.predecessor', data?.id || null);
  };

  const handleAddtionalSelectPredecessor = (e: any) => {
    const data = predecessorList.find(item => item.id === e);
    form?.change('general.sequence', data?.sequence || null);
  };

  return (
    <ModuleModalGeneralComponent
      handleAddtionalOnChangeSequence={handleAddtionalSelectSequence}
      handleAddtionalSelectPredecessor={handleAddtionalSelectPredecessor}
      {...props}
    />
  );
}
