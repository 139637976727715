import React from 'react';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import { Col, Row, Form as FormANTD } from 'antd';
import Select from '../../../Assets/Components/Select';
import { InputDatePicker } from '../../../Assets/Components/CInputDate';
import CButton from '../../../Assets/Components/CButton';
import Table from '../../../Assets/Components/CTable';
import { listJourney } from '../../../App/Enums';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { IResultAgentMonitoringDAO } from '../DAO/newAgentMonitoring.dao';

interface IProps {
  column: any;
  handleDownload: () => void;
  handleFilter: (values: any) => void;
  handleRefresh: (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>
  ) => void;
  isLoading: boolean;
  newAgentMonitoringList: IResultAgentMonitoringDAO[];
  renderRowSubComponent: any;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      date: any[];
      status: string;
    }>
  >;
  selectedFilter?: {
    date: any[];
    status: string;
  };
}

export default function NewAgentMonitoringComponent(props: IProps) {
  const {
    column,
    handleDownload,
    handleFilter,
    handleRefresh,
    isLoading,
    newAgentMonitoringList,
    renderRowSubComponent,
  } = props;

  const validateDowloadButton =
    newAgentMonitoringList && newAgentMonitoringList.length > 0 ? false : true;

  const buttonDownload = [
    {
      type: 'primary',
      icon: 'DownloadOutlined',
      onClick: handleDownload,
      content: 'Download berkas',
      disabled: validateDowloadButton,
      id: 'btnDownloadNewAgentmonitoringList',
    },
  ];

  const cardQaProcessContent = () => {
    const defaultDate = [moment().startOf('month'), moment().endOf('month')];

    return (
      <div className="containerWhiteZurich">
        <Form onSubmit={() => {}}>
          {formProps => {
            const { form, values } = formProps;

            const buttonData = [
              {
                type: 'primary',
                className: 'spacingBtnSecondRow2',
                icon: 'SearchOutlined',
                onClick: () => handleFilter(values),
                content: 'Cari',
                id: 'btnSearchLicenseList',
                disabled: values?.dateFilter === null,
              },
              {
                type: 'ghost',
                icon: 'ReloadOutlined',
                onClick: () => handleRefresh(form),
                content: 'Refresh',
                id: 'btnRefreshLicenseList',
              },
            ];

            return (
              <FormANTD>
                <Row gutter={20}>
                  <Col span={10}>
                    <label className="labelBtnFilter">Tanggal</label>
                    <Field
                      defaultValue={defaultDate}
                      name="dateFilter"
                      component={InputDatePicker}
                      idComponent="inputDateFilterListLicense"
                      typeDate="dateRangePicker"
                      placeholder={['Tanggal Mulai', 'Tanggal Berakhir']}
                    />
                  </Col>
                  <Col span={8}>
                    <label className="labelBtnFilter">Status</label>
                    <Field
                      name="journey"
                      component={Select}
                      idComponent="inputLicenseStatus"
                      dataOption={listJourney}
                      defaultItemName="Semua"
                    />
                  </Col>
                  <Col span={6} className={'btnFilterAplicationList'}>
                    <CButton
                      buttonData={buttonData}
                      buttonFloat={'right'}
                      isLoading={isLoading}
                    />
                  </Col>
                </Row>
              </FormANTD>
            );
          }}
        </Form>
        <Table
          isLoading={isLoading}
          columns={column}
          data={newAgentMonitoringList}
          pagination={true}
          useFilterGlobal
          renderRowSubComponent={renderRowSubComponent}
        />
        <Row>
          <Col>
            <CButton
              buttonData={buttonDownload}
              buttonFloat={'left'}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <CLabelCard leftText="New Agent Monitoring" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">Daftar Agen Baru</span>
        }
        cardContent={cardQaProcessContent()}
      />
    </>
  );
}
