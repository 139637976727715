import React from 'react';
import Select from '../../../Assets/Components/Select';
import moment from 'moment';
import { contestType } from '../../../App/Enums';
import { Field, Form } from 'react-final-form';
import Dashboard from '@uppy/react/lib/Dashboard';
import { Form as FormAntd } from 'antd';
import { localeUppy } from '../../Internationalization/translations/en';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { toast } from 'react-toastify';
import Store from '../../../Store';

interface IProps {
  isLoading: boolean;
}

export default function ContestTabUploadFileComponent(props: IProps) {
  const authState: any = Store.getState();
  const dateNow = moment();
  const yearNow = dateNow.format('YYYY');
  const yearPrev = dateNow.subtract(1, 'year').format('YYYY');
  const dataYear = [
    {
      id: yearNow,
      name: yearNow,
    },
    {
      id: yearPrev,
      name: yearPrev,
    },
  ];

  return (
    <div className="containerTabWhiteZurich">
      <Form layout="vertical" onSubmit={() => {}}>
        {formProps => {
          const { form, handleSubmit: handleSubmitForm, values } = formProps;

          const uppyUploadMonthly = new Uppy({
            restrictions: {
              minNumberOfFiles: 1,
              maxNumberOfFiles: 1,
              allowedFileTypes: ['.xls', '.xlsx'],
            },
            locale: localeUppy,
          }).use(XHRUpload, {
            id: `uppyFileGalleryXHR`,
            headers: {
              Authorization: `bearer ${authState.token}`,
            },
            endpoint: `${process.env.REACT_APP_API_URL}/contest/final/upload/excel`,
            method: 'post',
            formData: true,
            fieldName: 'file',
            allowedMetaFields: ['originalFilename', 'contestType', 'year'],
          });
          uppyUploadMonthly.on('file-added', file => {
            uppyUploadMonthly.setFileMeta(file.id, {
              originalFilename: file.name,
              contestType: values?.contestType,
              year: values?.year,
            });
          });
          uppyUploadMonthly.on('complete', file => {
            form.reset();
            toast.success('Success upload file', {
              className: 'toastSuccessBackground',
            });
          });

          return (
            <FormAntd layout="vertical" onFinish={handleSubmitForm}>
              <Field
                name="contestType"
                component={Select}
                dataOption={contestType}
                defaultItemName="Select contest type"
                label="Select contest type"
              />
              <Field
                name="year"
                component={Select}
                dataOption={dataYear}
                defaultItemName="Select Year"
                label="Select year"
              />
              {values.contestType && values.year ? (
                <>
                  <Dashboard
                    id="uploadMonthlyUpload"
                    uppy={uppyUploadMonthly}
                    width={'100%'}
                    proudlyDisplayPoweredByUppy={false}
                  />
                </>
              ) : null}
            </FormAntd>
          );
        }}
      </Form>
    </div>
  );
}
