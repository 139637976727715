import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Row,
  Skeleton,
  Tabs,
  Tag,
} from 'antd';
import {
  licenseStatus,
  notesTemplates,
  notesType,
} from '../../../../App/Enums';
import CButton from '../../../../Assets/Components/CButton';
import CCard from '../../../../Assets/Components/CCard';
import { DynamicTabs } from '../../../../Assets/Components/CDynamicTabs';
import CIcon from '../../../../Assets/Components/CIcon';
import { InputText } from '../../../../Assets/Components/CInput';
import { InputDatePicker } from '../../../../Assets/Components/CInputDate';
import { CInputSwitch } from '../../../../Assets/Components/CInputSwitch';
import { FormApi } from 'final-form';
import moment from 'moment';
import { Field, FormRenderProps } from 'react-final-form';
import { injectIntl } from 'react-intl';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { ViewImage } from '../../../../Assets/Components/CViewImageFormRegis';
import { InputFileRegisForm } from '../../../../Assets/Components/InputFileRegisForm';
import Select from '../../../../Assets/Components/Select';
import listDocument from '../../../../Assets/JSON/documentRegistration.json';
import { IResultCheckExamStatusDao } from '../../DAO/license.dao';
import validate from '../../Validation/LicenseModalFormValidation';

interface IProps {
  candidateApprovers: any;
  clean: boolean | null;
  column: any;
  detailCandidate: any;
  detailExam: any[];
  documentsContent: any;
  examStatus?: IResultCheckExamStatusDao;
  handleAddPendingItem: (values: any) => void;
  handleCancel: () => void;
  handleChangePendingStatus: (e: string, path: string) => void;
  handleClean: (form: FormApi<any, Partial<any>>) => void;
  handleDeletePendingTemp: (id: any) => void;
  handleNotClean: () => void;
  handleSubmit: (values: any) => void;
  handleSubmitPaymentSlipChecking: () => void;
  handleSubmitPendingItem: (form: FormApi<any, Partial<any>>) => void;
  handleUploadContent: (file: any, additionalMeta: any) => void;
  imageHistory: any[];
  initialValues: any;
  isLoading: boolean;
  isLoadingImageHistory: boolean;
  isInitialLoading: boolean;
  isLoadingPaymentSlipChecking: boolean;
  isSuccessUploadPaymentSlip: boolean;
  listAgreement: any[];
  listContent: any[];
  listFilenet: any[];
  listPendingItem: any[];
  listPendingItemTemp: any[];
  listQaPendingItem: any[];
  modalIsShow: boolean;
  pendingItem: any[];
  renderStatus: (val: any) => void;
  schema: any;
  submission: any;
  intl?: any;
}

const defaultModalstyles = {
  content: {
    position: 'relative',
    background: 'none',
    maxWidth: '95vw',
    width: '85vw',
    padding: '0px',
    border: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: '90',
  },
};

function ModalLicenseComponent(props: IProps) {
  const {
    candidateApprovers,
    clean,
    column,
    detailCandidate,
    detailExam,
    documentsContent,
    examStatus,
    handleAddPendingItem,
    handleCancel,
    handleChangePendingStatus,
    handleClean,
    handleDeletePendingTemp,
    handleNotClean,
    handleSubmit,
    handleSubmitPaymentSlipChecking,
    handleSubmitPendingItem,
    handleUploadContent,
    imageHistory,
    initialValues,
    intl,
    isLoading,
    isLoadingImageHistory,
    isInitialLoading,
    isLoadingPaymentSlipChecking,
    isSuccessUploadPaymentSlip,
    listAgreement,
    listContent,
    listFilenet,
    listPendingItem,
    listPendingItemTemp,
    listQaPendingItem,
    modalIsShow,
    renderStatus,
    schema,
    submission,
  } = props;

  const { TabPane } = Tabs;
  const listDocumentData = listDocument.data;

  const agencyData = submission?.submission?.personaldata?.agency;
  const taxBankData = submission?.submission?.personaldata?.taxbankaccount;
  const profileAgency = {
    candidateLevel: agencyData?.candidateLevel,
    recruiter: candidateApprovers?.name,
    directLeader: agencyData?.directLeader,
    agencyLevel: candidateApprovers?.approverLevel,
    agency: agencyData?.agency,
    salesOffice: agencyData?.salesOffice,
  };
  const personalData = {
    agency: profileAgency,
    profile: submission?.submission?.personaldata?.profile,
    addresseducation: submission?.submission?.personaldata?.addresseducation,
    taxbankaccount: {
      bank: taxBankData?.bank,
      tax: {
        taxpayer: taxBankData?.tax?.taxpayer,
        taxownership: taxBankData?.tax?.taxownership,
        taxdependents: taxBankData?.tax?.taxdependents,
      },
    },
  };

  const jsonTemp = {
    personaldata: personalData,
    beneficiary: submission?.submission?.beneficiary,
    workexperience: submission?.submission?.workexperience,
    documents: submission?.submission?.documents,
    useragreements: submission?.submission?.useragreements,
    domicileaddress: submission?.submission?.domicileaddress,
  };

  const headerComponent = () => {
    return <h3 className="headerTitle capital">Detail Lisensi</h3>;
  };

  const renderDetailCandidate = (label: string, value: string) => {
    let contentValue = '';
    switch (value) {
      case 'MUTASI': {
        contentValue = 'Mutation';
        break;
      }
      case 'EXPIRED': {
        contentValue = 'Expired';
        break;
      }
      case 'NOLICENSE': {
        contentValue = 'No License';
        break;
      }
      default:
        contentValue = value;
    }
    return (
      <Row className={'detailCandidateLicense'}>
        <Col span={10}>{label}</Col>
        <Col span={1}>:</Col>
        <Col span={10}>{contentValue}</Col>
      </Row>
    );
  };

  const headerDataCandidateContent = () => {
    return (
      <div>
        <Row>
          <Col span={12}>
            <span className="titleLicenseModal">Data Kandidat</span>
          </Col>

          <Col span={12}>
            <span className={'titlePopupLicenseStatus'}>Status saat ini:</span>
            <br />
            {renderStatus(detailCandidate?.progressStatus)}
          </Col>
        </Row>
      </div>
    );
  };

  const contentActionQaClean = () => {
    return (
      <div>
        <span className="titleContentLicenseModal">Status Lisensi</span>
        <Row className="contentLicenseModal">
          <Col>
            <Form layout="vertical">
              <Field
                name="category"
                component={Select}
                idComponent="inputCategory"
                dataOption={licenseStatus}
                label={'Kategori'}
                defaultItemName={'Pilih'}
                disabled={false}
                className={'selectLicense'}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  };

  const contentActionAgent = () => {
    return (
      <Row>
        <Col>
          <Form layout="vertical">
            <Field
              name="licenseId"
              component={InputText}
              idComponent="inputLicenseId"
              icon={<CIcon type="FileTextOutlined" />}
              placeholder={'Ketik disini'}
              label={'ID Lisensi'}
              disabled={false}
              maxlength={20}
            />
            <Field
              name={'effectiveDate'}
              component={InputDatePicker}
              idComponent={'inputEffectiveDate'}
              label={intl.formatMessage({ id: 'effectiveDateInd' })}
              typeDate="datePicker"
              placeholder="DD-MM-YYYY"
            />
            <Field
              name={'expiredDate'}
              component={InputDatePicker}
              idComponent={'inputExpiredDate'}
              label={intl.formatMessage({ id: 'expiredDate' })}
              typeDate="datePicker"
              placeholder="DD-MM-YYYY"
            />
          </Form>
        </Col>
      </Row>
    );
  };

  const contentActionExam = () => {
    let cssExamStatus = '';
    let textExamStatus = '';
    if (examStatus && examStatus.isExamPassed === null) {
      textExamStatus = 'Sedang Proses';
      cssExamStatus = 'txtStatusDefault';
    } else if (examStatus && examStatus.isExamPassed === 'Passed') {
      textExamStatus = 'Passed';
      cssExamStatus = 'txtStatusClean';
    } else {
      textExamStatus = 'Not Passed';
      cssExamStatus = 'txtStatusNotClean';
    }

    return (
      <div>
        <span className={'titleLicenseModal'}>Exam Result</span>
        <br />
        <div className="txtExamStatus">Status ujian:</div>
        <div className={cssExamStatus}>{textExamStatus}</div>
      </div>
    );
  };

  const contentActionPaymentSlipChecking = (
    form: FormApi<any, Partial<any>>,
    formValues: any
  ) => {
    let txtStatus = '';
    let cssColor = '';
    if (clean === true) {
      txtStatus = 'Clean';
      cssColor = 'txtStatusClean';
    } else if (clean === false) {
      txtStatus = 'Not Clean';
      cssColor = 'txtStatusNotClean';
    } else {
      txtStatus = 'Belum Memilih';
      cssColor = 'txtStatusDefault';
    }
    const validate = clean || clean === null ? true : false;
    const formValueNoteType = formValues ? formValues.noteType : null;
    const formValueCustomNote = formValues ? formValues.customNote : null;
    const formValueTemplateNote = formValues ? formValues.noteTemplates : null;
    const disabled =
      formValueTemplateNote || formValueCustomNote ? false : true;

    const dataButtonAddPendingItem = [
      {
        type: 'primary',
        id: 'btnAddPendingRemarks',
        className: 'btnAddPendingRemarks',
        content: 'Tambah Catatan',
        icon: 'PlusOutlined',
        disabled,
        onClick: () => handleAddPendingItem(formValues),
      },
    ];

    const dataButtonSubmitPendingItem = [
      {
        type: 'primary',
        id: 'btnSubmitQa',
        className: 'btnSubmitQa',
        content: 'Tambah Item Pending',
        disabled: listPendingItemTemp.length !== 0 ? false : true,
        onClick: () => handleSubmitPendingItem(form),
      },
    ];

    const buttonDataPaymentSlipChecking = [
      {
        type: 'primary',
        id: 'btnSubmitPaymentSlipChecking',
        className: 'btnNotClean',
        content: 'Not Clean',
        onClick: handleNotClean,
        disabled: clean === false,
      },
      {
        type: 'primary',
        id: 'btnClean',
        className: 'btnClean',
        content: 'Clean',
        onClick: () => handleClean(form),
        disabled: clean,
      },
    ];

    return (
      <>
        <div className="txtTitleStatusClean">Status saat ini:</div>
        <div className={cssColor}>{txtStatus}</div>
        <CButton buttonData={buttonDataPaymentSlipChecking} />
        <hr className="lineDetailQaStatus" />
        <span>Catatan</span>
        <Form layout="vertical" className={'selectNotesPaymentSlip'}>
          <Field
            name="noteType"
            component={Select}
            idComponent="inputNoteType"
            dataOption={notesType}
            label={'Tipe Catatan'}
            defaultItemName={'Pilih'}
            disabled={validate}
          />
          <div hidden={formValueNoteType === 'template' ? false : true}>
            <Field
              name="noteTemplates"
              component={Select}
              idComponent="inputNoteTemplates"
              dataOption={notesTemplates}
              label={'Template Catatan'}
              defaultItemName={'Pilih'}
              disabled={validate}
            />
          </div>
          <div hidden={formValueNoteType === 'custom' ? false : true}>
            <Field
              name="customNote"
              component={InputText}
              idComponent="inputCustomNote"
              placeholder={'Ketik disini'}
              label={'Kustomisasi Catatan'}
            />
          </div>
        </Form>
        <CButton buttonData={dataButtonAddPendingItem} />
        <div>
          {listPendingItemTemp.map((data, index) => {
            return (
              <div
                key={`itemPending_${index}`}
                className="containerItemPending"
              >
                <Button
                  id={`btnDeleteItemPending_${index}`}
                  className="btnDeleteItemPending"
                  icon={<DeleteOutlined style={{ fontSize: 20 }} />}
                  onClick={() => handleDeletePendingTemp(data.id)}
                />
                <div className="txtTitleItemPending">
                  {data.config && data.config.note
                    ? data.config.note
                    : data.title}
                </div>
              </div>
            );
          })}
        </div>
        <hr className="lineDetailQaStatus" />
        <CButton buttonData={dataButtonSubmitPendingItem} />
      </>
    );
  };

  const contentActionUploadingPayment = () => {
    return (
      <div>
        <span className={'titleLicenseModal'}>Uploading Payment Slip</span>
        <br />
        <p className={'spacingTitleLicenseModal'}>
          Menunggu Unggahan Payment Slip
        </p>
      </div>
    );
  };

  const contentDetailCandidate = () => {
    const detailCandidateLevel = detailCandidate
      ? detailCandidate.candidateLevel
      : '';
    return (
      <>
        <Row gutter={12} className="contentLicenseDetailCandidate">
          <Col span={12}>
            {renderDetailCandidate('Nomor Agen', detailCandidate?.agentNumber)}
            {renderDetailCandidate(
              'Nomor Aplikasi',
              detailCandidate?.aplicationNumber
            )}
            {renderDetailCandidate('Sub Channel', detailCandidate?.subChannel)}
            {renderDetailCandidate(
              'Nama Kandidat',
              detailCandidate?.candidateName
            )}
            {renderDetailCandidate('SOB', detailCandidate?.sob)}
            {renderDetailCandidate('Origin', detailCandidate?.origin)}
          </Col>
          <Col span={12}>
            {renderDetailCandidate(
              'Level Agen',
              candidateApprovers?.approverLevel
            )}
            {renderDetailCandidate(
              'Klasifikasi Agen',
              detailCandidate?.clasificationAgent
            )}
            {renderDetailCandidate(
              'Status Lisensi',
              detailCandidate?.licenseStatus
            )}
            {renderDetailCandidate('Kode Agensi', detailCandidate?.agencyCode)}
            {renderDetailCandidate('Nama Agensi', detailCandidate?.agencyName)}
            {renderDetailCandidate(
              'Kantor Sales',
              detailCandidate?.salesOffice
            )}
          </Col>
        </Row>
        <div>
          {documentsContent && submission && schema ? (
            <DynamicTabs
              json={jsonTemp}
              intl={intl}
              column={column}
              schema={schema.schema.properties}
              candidateLevel={detailCandidateLevel}
              documents={documentsContent}
              docAgreement={listAgreement}
              listContent={listContent}
            />
          ) : null}
        </div>
      </>
    );
  };

  const tagStat = (tagStatus: string, color: string) => {
    return (
      <Tag color={color}>
        <div className={'tagLabelStatusLicense'}>{tagStatus}</div>
      </Tag>
    );
  };

  const renderStatusPending = (status: string) => {
    switch (status) {
      case 'active': {
        return tagStat('Pending Aktif', '#D82D2D');
      }
      case 'finish': {
        return tagStat('Pending Selesai', '#1DB227');
      }
      case 'update': {
        return tagStat('Pending Diperbarui', '#F8B720');
      }
    }
    return null;
  };

  const renderExam = () => {
    return detailExam.map((data, index) => {
      if (data.examType === 0) {
        return (
          <div
            key={`examSchedule${index}`}
            style={{
              marginBottom: 20,
              backgroundColor: '#fafbfb',
              padding: 10,
            }}
          >
            <div className="txtTitleLocation">Lokasi Ujian</div>
            <div className="txtLocationExam">{data.city}</div>
            <div>
              <CIcon
                type="BankOutlined"
                style={{ marginTop: 7, marginRight: 7 }}
              />
              {data.address}
            </div>
            <hr className="lineExamLicense" />
            <div>
              <CIcon
                type="DesktopOutlined"
                style={{ marginTop: 7, marginRight: 7 }}
              />
              Online
            </div>
            <CIcon
              type="CalendarOutlined"
              style={{ marginTop: 7, marginRight: 7 }}
            />
            {moment(data.examDate).format('MMMM Do YYYY')}
            <CIcon
              type="ClockCircleOutlined"
              style={{ marginTop: 7, marginRight: 7, marginLeft: 20 }}
            />
            {data.startTime} - {data.endTime}
          </div>
        );
      }

      return (
        <div
          key={`examSchedule${index}`}
          style={{ marginBottom: 20, backgroundColor: '#fafbfb', padding: 10 }}
        >
          <div>
            <CIcon
              type="BankOutlined"
              style={{ marginTop: 7, marginRight: 7 }}
            />
            {data.address}
          </div>
          <hr className="lineExamLicense" />
          <div>
            <CIcon
              type="DesktopOutlined"
              style={{ marginTop: 7, marginRight: 7 }}
            />
            Mobile Apps
          </div>
        </div>
      );
    });
  };

  const contentMiddleCard = (form: FormApi<any, Partial<any>>, values: any) => {
    const buttonDataSubmitItemPending = [
      {
        type: 'primary',
        className: 'btnSubmitLicenseQaClean',
        onClick: handleSubmitPaymentSlipChecking,
        content: 'Ajukan',
        id: 'btnSubmitLicenseQaClean',
        disabled: clean === null ? true : false,
      },
    ];

    const listActiveLicense = listPendingItem.filter(obj => {
      return obj.config.pendingStatus === 'active';
    });
    const listFinishLicense = listPendingItem.filter(obj => {
      return obj.config.pendingStatus === 'finish';
    });
    const listActiveQa = listQaPendingItem.filter(obj => {
      return obj.config.pendingStatus === 'active';
    });
    const listFinishQa = listQaPendingItem.filter(obj => {
      return obj.config.pendingStatus === 'finish';
    });

    const progressStatus = () => {
      if (detailCandidate?.progressStatus !== 'Payment Slip Checking') {
        return true;
      }
      else if (clean !== null) {
        return false;
      }
      return true;
    };

    const handleOnChange = (file: any, additionalMeta: any) => {
      handleUploadContent(file, additionalMeta);
      form.change('uploadPaymentSlip', null);
    };

    const uppyPaymentSlip: any = {};
    for (const iterator of listDocumentData) {
      if (iterator.name === 'uploadPaymentSlip') {
        uppyPaymentSlip.maxFile = 1;
        uppyPaymentSlip.minFile = 1;
        uppyPaymentSlip.allowedFile = iterator.extension;
        uppyPaymentSlip.maxSize = iterator.maxSize * 1048576;
        uppyPaymentSlip.documentCode = iterator.code;
        uppyPaymentSlip.documentType = iterator.type;
        uppyPaymentSlip.name = iterator.name;
        uppyPaymentSlip.sectionKey = 'licenseForm';
        uppyPaymentSlip.fileName = iterator.fileName;
        uppyPaymentSlip.documentGroup = iterator.documentGroup;
      }
    }

    return (
      <Tabs defaultActiveKey="itemPending" type="card" className="dynamicTabs">
        {detailCandidate?.licenseStatus === 'NOLICENSE' ? (
          <TabPane
            tab="Payment Slip"
            key="paymentSlip"
            className="tabsPaneDynamic"
          >
            <Skeleton
              active
              loading={
                isSuccessUploadPaymentSlip ? isLoadingImageHistory : false
              }
            >
              <div
                hidden={
                  detailCandidate && detailCandidate.origin === 'Manual'
                    ? false
                    : true
                }
              >
                <Row className="licenseSpacingTabRow">
                  <Form layout="vertical">
                    {values &&
                    values.uploadPaymentSlip &&
                    documentsContent &&
                    documentsContent.uploadPaymentSlip ? (
                      <ViewImage
                        documents={documentsContent}
                        name="uploadPaymentSlip"
                        imgClassName="imgEdit"
                        label="Unggah Berkas"
                        fileName={
                          documentsContent['uploadPaymentSlip'].originalFileName
                        }
                        size={
                          documentsContent['uploadPaymentSlip'].properties.size
                        }
                        path={'uploadPaymentSlip'}
                        formName="licenseForm"
                        extension={
                          documentsContent['uploadPaymentSlip'].properties
                            .extension
                        }
                        width={400}
                      />
                    ) : (
                      <Field
                        name="uploadPaymentSlip"
                        component={InputFileRegisForm}
                        label="uploadPaymentSlip"
                        idComponent="inputUploadPaymentSlip"
                        formName="licenseForm"
                        fieldName={'uploadPaymentSlip'}
                        uppyConfig={uppyPaymentSlip}
                        base64ToMeta={true}
                        height={200}
                        useFile={true}
                        handleOnChange={handleOnChange}
                      />
                    )}
                  </Form>
                </Row>
              </div>
              <Row gutter={20} className="licenseSpacingTabRow">
                {imageHistory.length > 0
                  ? imageHistory.map((data, index) => {
                      return (
                        <Col key={`uploadPaymentSlip${index}`} span={8}>
                          <ViewImage
                            base64={`data:${data.properties.mime};base64, ${data.content}`}
                            fileName={data['originalFileName']}
                            size={data['properties']['size']}
                            label="Unggahan Berkas"
                            path="paymentSlip"
                            formName="paymentSlip"
                            formClassName="uploadAajiQa"
                            disabled={true}
                            labelInfoNew={
                              documentsContent &&
                              documentsContent.uploadPaymentSlip
                                ? documentsContent.uploadPaymentSlip.id ===
                                  data.id
                                : false
                            }
                          />
                        </Col>
                      );
                    })
                  : null}
                {/* </Skeleton> */}
              </Row>
            </Skeleton>
          </TabPane>
        ) : null}
        <TabPane
          tab={
            <Badge
              count={listActiveQa.length + listActiveLicense.length}
              offset={[12, 5]}
            >
              Item Pending{`  `}
            </Badge>
          }
          key="itemPending"
          className="tabsPaneDynamic"
        >
          <p className="paragraphTabItemPending">
            <span className="spanTabItemPending">QA Process</span> ({' '}
            {listFinishQa.length} Selesai,{` `}
            {listActiveQa.length} Aktif)
          </p>
          <div className="containerPendingItemLicense">
            {listQaPendingItem.map((data, index) => {
              if (data.config.note) {
                return (
                  <div
                    key={`itemPendingQa_${index}`}
                    className="containerItemPending"
                  >
                    <Row>
                      <Col span={1}>
                        <div style={{ marginTop: 25 }}>{`${index + 1}.`}</div>
                      </Col>
                      <Col span={14}>
                        <div>
                          {renderStatusPending(data.config.pendingStatus)}
                        </div>
                        <div className="containerTxtItemPending">
                          <div className="titleItemPending">Label</div>
                          <div className="txtItemPendingRightContent">
                            {data.title}
                          </div>
                          <div className="titleItemPending">Catatan</div>
                          <div className="txtItemPendingRightContent">
                            {data.config.note}
                          </div>
                        </div>
                      </Col>
                      <Col span={2} offset={7}>
                        <div
                          hidden={
                            detailCandidate?.progressStatus !==
                            'Payment Slip Checking'
                              ? true
                              : false
                          }
                        >
                          <Form layout="vertical">
                            <Field
                              name={`pendingItemQa.${data.name}`}
                              component={CInputSwitch}
                              idComponent={`inputSwitchQa_${index}`}
                              labelTitle={
                                data.config.pendingStatus === 'finish'
                                  ? 'Tidak Aktif'
                                  : 'Aktif'
                              }
                              defaultChecked={
                                data.config.pendingStatus === 'finish'
                                  ? false
                                  : true
                              }
                              path={
                                data.config.path
                                  ? data.config.path
                                  : data.config.name
                              }
                              addtionalFunction={handleChangePendingStatus}
                            />
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              }
              return (
                <div
                  key={`itemPendingQa_${index}`}
                  className="containerItemPending"
                >
                  <Row>
                    <Col span={1}>
                      <div style={{ marginTop: 25 }}>{`${index + 1}.`}</div>
                    </Col>
                    <Col span={14}>
                      <div>
                        {renderStatusPending(data.config.pendingStatus)}
                      </div>
                      <div className="txtItemPending">{data.title}</div>
                    </Col>
                    <Col span={2} offset={7}>
                      <div
                        hidden={
                          detailCandidate?.progressStatus !==
                          'Payment Slip Checking'
                            ? true
                            : false
                        }
                      >
                        <Form layout="vertical">
                          <Field
                            name={`pendingItemQa.${data.name}`}
                            component={CInputSwitch}
                            idComponent={`inputSwitchQa_${index}`}
                            labelTitle={
                              data.config.pendingStatus === 'finish'
                                ? 'Tidak Aktif'
                                : 'Aktif'
                            }
                            defaultChecked={
                              data.config.pendingStatus === 'finish'
                                ? false
                                : true
                            }
                            path={
                              data.config.path
                                ? data.config.path
                                : data.config.name
                            }
                            addtionalFunction={handleChangePendingStatus}
                          />
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          {detailCandidate?.licenseStatus === 'NOLICENSE' ? (
            <div>
              <p>
                <span style={{ fontWeight: 'bold', color: '#003399' }}>
                  Lisensi
                </span>{' '}
                ( {listFinishLicense.length} Selesai,{` `}
                {listActiveLicense.length} Aktif)
              </p>
              <div className="containerPendingItemLicense">
                {listPendingItem.map((data, index) => {
                  return (
                    <div
                      key={`itemPending_${index}`}
                      className="containerItemPending"
                    >
                      <Row>
                        <Col span={1}>
                          <div style={{ marginTop: 25 }}>{`${index + 1}.`}</div>
                        </Col>
                        <Col span={14}>
                          <div>
                            {renderStatusPending(data.config.pendingStatus)}
                          </div>
                          <div className="txtItemPending">{data.title}</div>
                        </Col>
                        <Col span={2} offset={7}>
                          <div
                            hidden={
                              detailCandidate?.progressStatus !==
                              'Payment Slip Checking'
                                ? true
                                : false
                            }
                          >
                            <Form layout="vertical">
                              <Field
                                name={`pendingItemLicense.${data.name}`}
                                component={CInputSwitch}
                                idComponent={`inputSwitchLicense_${index}`}
                                labelTitle={
                                  data.config.pendingStatus === 'finish'
                                    ? 'Tidak Aktif'
                                    : 'Aktif'
                                }
                                defaultChecked={
                                  data.config.pendingStatus === 'finish'
                                    ? false
                                    : true
                                }
                                path={
                                  data.config.path
                                    ? data.config.path
                                    : data.config.name
                                }
                                addtionalFunction={handleChangePendingStatus}
                              />
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
              <div hidden={listPendingItem.length !== 0 ? false : true}>
                <hr className={'lineLicense'} />
              </div>
            </div>
          ) : null}
          <div hidden={progressStatus()}>
            <CButton
              isLoading={isLoadingPaymentSlipChecking}
              buttonData={buttonDataSubmitItemPending}
            />
          </div>
        </TabPane>
        {detailCandidate?.licenseStatus === 'NOLICENSE' ? (
          <TabPane tab="Exam" key="exam" className="tabsPaneDynamic">
            {renderExam()}
          </TabPane>
        ) : null}
      </Tabs>
    );
  };

  const showCard = (form: FormApi<any, Partial<any>>, formValues: any) => {
    const returnHeader = () => {
      const showAlertExam = () => {
        return (
          <Alert
            message={
              <span>
                Calon agen ini <b>Belum lulus ujian AAJI!</b>
              </span>
            }
            type="warning"
            showIcon
          />
        );
      };
      const showAlertZel = () => {
        return (
          <Alert
            message={
              <span>
                Calon agen ini <b>Belum Lulus Training!</b>
              </span>
            }
            type="warning"
            showIcon
          />
        );
      };

      switch (detailCandidate?.progressStatus) {
        case 'Waiting for Training':
        case 'CPD Training':
        case 'Exam Passed':
        case 'DOSD Need to Activate': {
          return (
            <div>
              {examStatus && examStatus.isExamPassed !== 'Passed'
                ? showAlertExam()
                : null}
              {examStatus !== null && !examStatus?.isZel
                ? showAlertZel()
                : null}
              <span className="titleLicenseModal">Aktivasi Agen</span>
            </div>
          );
        }
      }
      return null;
    };

    const content = (form: FormApi<any, Partial<any>>, formValues: any) => {
      const formValueCategory = formValues ? formValues.category : null;
      const formValueLicenseId = formValues ? formValues.licenseId : null;
      const formValueEffectiveDate = formValues
        ? formValues.effectiveDate
        : null;
      const formValueExpiredDate = formValues ? formValues.expiredDate : null;
      const validateButton =
        formValueLicenseId && formValueEffectiveDate && formValueExpiredDate
          ? false
          : true;
      const validation =
        detailCandidate?.progressStatus === 'QA Clean'
          ? !formValueCategory
          : validateButton;

      const buttonData = [
        {
          type: 'primary',
          className: 'btnSubmitLicenseQaClean',
          onClick: () => handleSubmit(formValues),
          content: 'Ajukan',
          id: 'btnSubmitLicenseQaClean',
          disabled: validation,
        },
      ];

      const returnContent = () => {
        switch (detailCandidate?.progressStatus) {
          case 'QA Clean': {
            return contentActionQaClean();
          }
          case 'Exam Passed':
          case 'Waiting for Training':
          case 'CPD Training':
          case 'DOSD Need to Activate': {
            return contentActionAgent();
          }
          case 'Waiting For Exam Result': {
            return contentActionExam();
          }
          case 'Payment Slip Checking': {
            return contentActionPaymentSlipChecking(form, formValues);
          }
          case 'Uploading Payment Slip': {
            return contentActionUploadingPayment();
          }
        }
        return null;
      };

      const submitButton = () => {
        switch (detailCandidate?.progressStatus) {
          case 'QA Clean':
          case 'Exam Passed':
          case 'Waiting for Training':
          case 'CPD Training':
          case 'DOSD Need to Activate': {
            return (
              <>
                <hr className={'lineLicense'} />
                <CButton buttonData={buttonData} buttonFloat={'left'} />
              </>
            );
          }
        }
        return null;
      };
      return (
        <>
          {returnContent()}
          {submitButton()}
        </>
      );
    };

    switch (detailCandidate?.progressStatus) {
      case 'Agent Activated': {
        return <>{renderFileNet(formValues)}</>;
      }
      case 'Payment Slip Checking':
      case 'Waiting For Exam Result':
      case 'Exam Passed': {
        return (
          <>
            <CCard
              cardClassName="cardLicense"
              cardTitle={returnHeader()}
              cardContent={content(form, formValues)}
            />
            <CCard
              cardClassName="cardLicense"
              cardTitle={null}
              cardContent={contentMiddleCard(form, formValues)}
            />
            {renderFileNet(formValues)}
          </>
        );
      }
      case 'Uploading Payment Slip': {
        return (
          <>
            <div>
              <CCard
                cardClassName="cardLicense"
                cardTitle={returnHeader()}
                cardContent={content(form, formValues)}
              />
            </div>
            <div hidden={imageHistory.length !== 0 ? false : true}>
              <CCard
                cardClassName="cardLicense"
                cardTitle={null}
                cardContent={contentMiddleCard(form, formValues)}
              />
            </div>
            {renderFileNet(formValues)}
          </>
        );
      }
      case 'QA Clean': {
        return (
          <>
            <CCard
              cardClassName="cardLicense"
              cardTitle={returnHeader()}
              cardContent={content(form, formValues)}
            />
            {renderFileNet(formValues)}
          </>
        );
      }
      case 'CPD Training': {
        return (
          <>
            <CCard
              cardClassName="cardLicense"
              cardTitle={returnHeader()}
              cardContent={content(form, formValues)}
            />
            {renderFileNet(formValues)}
            <CCard
              cardClassName="cardLicense"
              cardTitle={null}
              cardContent={contentMiddleCard(form, formValues)}
            />
          </>
        );
      }
      case 'DOSD Need to Activate':
      case 'Waiting for Training': {
        return (
          <>
            <CCard
              cardClassName="cardLicense"
              cardTitle={returnHeader()}
              cardContent={content(form, formValues)}
            />
            {renderFileNet(formValues)}
            <CCard
              cardClassName="cardLicense"
              cardTitle={null}
              cardContent={contentMiddleCard(form, formValues)}
            />
          </>
        );
      }
    }
    return null;
  };

  const renderFileNet = (formValues: any) => {
    const headerCard = () => {
      return <span className="titleLicenseModal">Filenet</span>;
    };

    const contentCard = () => {
      const onclickFilenet = () => {
        const objectId = formValues.filenetId.split('_')[0];
        window.open(
          `http://hkwapp69:9080/navigator/bookmark.jsp?desktop=User&repositoryId=Zurich&docid=${objectId}`
        );
      };
      const dataButtonFileNet = [
        {
          type: 'primary',
          id: 'btnFilenet',
          className: 'buttonViewFileNet',
          content: 'Lihat File',
          disabled: formValues && formValues.filenetId ? false : true,
          onClick: () => onclickFilenet(),
        },
      ];
      return (
        <>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={10}>
                <Field
                  name="filenetId"
                  component={Select}
                  idComponent="inputFileNetId"
                  label="Pilih File"
                  defaultItemName={'Pilih'}
                  dataOption={listFilenet}
                />
              </Col>
              <Col span={4}>
                <CButton buttonData={dataButtonFileNet} />
              </Col>
            </Row>
          </Form>
        </>
      );
    };
    return (
      <CCard
        cardClassName="cardLicense"
        cardTitle={headerCard()}
        cardContent={contentCard()}
      />
    );
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const { form, values } = formProps;
    return (
      <div>
        {showCard(form, values)}
        <CCard
          cardClassName="cardLicense"
          cardTitle={headerDataCandidateContent()}
          cardContent={contentDetailCandidate()}
        />
      </div>
    );
  };

  return (
    <>
      <CModalRFF
        contentClassName="contentLicense"
        contentComponent={formProps => contentComponent(formProps)}
        footerComponent={null}
        handleCancel={handleCancel}
        handleSubmit={() => {}}
        handleValidation={validate}
        headerComponent={headerComponent()}
        initialValues={initialValues}
        isForm={true}
        isLoading={isInitialLoading && isLoading ? isLoading : false}
        modalIsShow={modalIsShow}
        modalstyles={defaultModalstyles}
      />
    </>
  );
}
export default injectIntl(ModalLicenseComponent);
