export const moduleValidation = (values: any) => {
  const { general: generalValue } = values;
  let errors: any = {};
  let general: any = {};

  // General validation
  if (generalValue) {
    if (!generalValue.name) general.name = 'Module Name Wajib Diisi!';
    if (generalValue.name && !/^[^\s].*/.test(generalValue.name)) {
      general.name = 'Use letters/numbers at the beginning of words!';
    }
    if (generalValue.sequence && !generalValue.predecessor) {
      general.predecessor = 'Predecessor Wajib Diisi!';
    }
    if (generalValue.isCertificate === true && !generalValue.certificateId) {
      general.certificateId = 'List Sertifikat Wajib Diisi!';
    }
    if (generalValue.isCpdStatus === true && !generalValue.cpdRefreshName) {
      general.cpdRefreshName = 'CPD Refreshment Name Wajib Diisi!';
    } else if (
      generalValue.cpdRefreshName &&
      !/^[^\s].*/.test(generalValue.cpdRefreshName)
    ) {
      general.cpdRefreshName = 'Use letters/numbers at the beginning of words!';
    }
    if (generalValue.isCpdStatus === true && !generalValue.cpdPoint) {
      general.cpdPoint = 'CPD Poin Wajib Diisi!';
    }
    if (!generalValue.defaultLearningHour) {
      general.defaultLearningHour = 'Default Learning Hour Wajib Diisi!';
    }
  }

  errors = { general: Object.keys(general).length > 0 ? general : undefined };
  return errors;
};

export const moduleMigrationValidation = (values: any) => {
  const { stage, module } = values;
  const errors: any = {};

  if (!stage) errors.stage = 'Stage Wajib Diisi!';

  if (!module) errors.module = 'Module Wajib Diisi!';

  return errors;
};
