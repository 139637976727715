import React from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';
import Store from '../../../Store';

export default function SessionTimeoutContainer() {
  const { configuration, logOut }: any = Store.getState();
  const idleMinute = configuration.find(i => i.parameter === "application.idle.cms")?.parameterValue;
  const promptTimer = useIdleTimer({
    timeout: 1000 * (idleMinute ? parseInt(idleMinute) : 60),
    promptBeforeIdle: 0,
    crossTab: true,
    syncTimers: 0,
    name: 'activity-timer-zurich',
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
    onIdle: () => {
      logOut();
    },
    onAction: () => {
      promptTimer.reset();
    },
  });

  return <></>;
}
