import { Col, Row, Switch } from 'antd';
import React, { useState } from 'react';
import {
  deleteNonSelfTrainingClassAPI,
  getClassDetailAPI,
  getNonSelfTrainingClassAPI,
  mutateChangeStatusClass,
} from '../../../../Services/classNonSelf.api';
import { useMutation, useQuery } from '@tanstack/react-query';

import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import ClassComponent from '../Component/ClassComponent';
import ClassModalContainer from './ClassModalContainer';
import { toast } from 'react-toastify';
import { toastr } from 'react-redux-toastr';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  selectedStatus?: string;
  stage: any;
  channel: any;
  setTabData: (val: any) => void;
}

export default function ClassContainer({ stage, channel, setTabData }: IProps) {
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter]: any = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedClass, setSelectedClass] = useState();

  const [selectedTraining, setSelectedTraining] = useState();
  const [selectedData, setSelectedData]: any = useState();

  const {
    data: dataClass,
    isLoading,
    isRefetching,
    refetch: refetchDataClass,
  } = useQuery(
    ['getNonSelfTrainingClassAPI', channel?.id, stage?.id, filter],
    getNonSelfTrainingClassAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataClassDetail,
    isLoading: isLoadingClassDetail,
    fetchStatus: fetchStatusClassDetail,
    refetch: refetchClassDetail,
  } = useQuery(
    ['getNonSelfTrainingClassDetailAPI', selectedData?.id],
    getClassDetailAPI,
    { enabled: selectedData !== undefined, refetchOnWindowFocus: false }
  );

  const deleteClassMutation = useMutation(
    (vals: any) => {
      return deleteNonSelfTrainingClassAPI(vals);
    },
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success(
          <span className="capitalFirst">Class Berhasil Dihapus</span>
        );
        refetchDataClass();
      },
    }
  );

  const mutationUpdateStatus = useMutation(mutateChangeStatusClass, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      toast.success(
        <span className="capitalFirst">Class Berhasil Diupdate!</span>
      );
    },
  });

  const processStatusClass = (row, actionProcess) => {
    mutationUpdateStatus.mutate(
      { id: row.id, status: actionProcess },
      { onSuccess: () => refetchDataClass() }
    );
  };
  const processEnabledStatus = (id, status, row) => {
    row.row.original.isActive = status;
    const action = status === true ? 'meng-aktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => processStatusClass(row.row.original, status),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Class "${row.row.original.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = row => {
    const rowData = row.row.original;
    const processEnabled = isChecked =>
      processEnabledStatus(rowData.id, isChecked, row);
    const dataActive = rowData.isActive;

    const isActive =
      typeof dataActive === 'string'
        ? dataActive.toLowerCase() === 'true'
        : dataActive;

    return (
      <Switch onChange={processEnabled} checked={isActive} id={rowData.id} />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => deleteClassMutation.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Class "${rows.name}"`, toastrConfirmOptions);
    };
    const dataButton = [
      {
        id: `btnDeleteClass`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
      },
      {
        id: `btnEditClass`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setShowModal(true);
        },
        className: 'btnEdit',
      },
      // Ini dicomment karena tab content di hide & akan dipake taun depan
      // {
      //   id: 'btnNextClass',
      //   icon: 'ArrowRightOutlined',
      //   onClick: () => setTabData(prev => ({ ...prev, class: rows })),
      //   className: 'btnNext',
      // },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      id: 'expanderClass',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Class Name',
      accessor: 'name',
    },
    {
      Header: 'Class Type',
      accessor: row =>
        row.type.toLowerCase() === 'private' ? 'Private' : 'Non Private',
    },
    {
      Header: 'Training Type',
      accessor: row =>
        row.trainingType.toLowerCase() === 'roadmap'
          ? 'Roadmap'
          : 'Non Roadmap',
    },
    {
      Header: 'Certificate',
      accessor: row =>
        row.isCertificate.toLowerCase() === 'true' ? 'Yes' : 'No',
    },
    {
      Header: 'Status',
      Cell: (row: any) => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const renderCol = (label, content) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };
  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { description, totalSchedule, totalExam, totalFeedback } =
      row.original;
    return (
      <div>
        {renderCol(
          'Description',
          description === null || description === '' ? '-' : description
        )}
        {renderCol(
          'Total Schedule',
          totalSchedule === null || totalSchedule === '' ? '-' : totalSchedule
        )}
        {renderCol(
          'Total Exam',
          totalExam === null || totalExam === '' ? '-' : totalExam
        )}
        {renderCol(
          'Total Feedback',
          totalFeedback === null || totalFeedback === '' ? '-' : totalFeedback
        )}
      </div>
    );
  }, []);

  const handleFilter = () => {
    setFilter({
      status: selectedStatus,
      class: selectedClass,
      training: selectedTraining,
    });
  };
  const handleAdd = () => {
    setSelectedData();
    setShowModal(true);
  };

  return (
    <>
      <ClassComponent
        setTempFilterStatus={() => {}}
        columnData={columnData}
        dataClass={dataClass || []}
        invalid={false}
        isLoading={isLoading || isRefetching}
        selectedStatus={selectedStatus}
        selectedTraining={selectedTraining}
        selectedClass={selectedClass}
        setSelectedStatus={setSelectedStatus}
        setSelectedClass={setSelectedClass}
        setSelectedTraining={setSelectedTraining}
        handleFilter={handleFilter}
        handleRefresh={() => {
          setSelectedStatus(undefined);
          setSelectedClass(undefined);
          setSelectedTraining(undefined);
          setFilter();
          refetchDataClass();
        }}
        handleAdd={handleAdd}
        renderRowSubComponent={renderRowSubComponent}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      {showModal && (
        <ClassModalContainer
          setShowModal={setShowModal}
          selectedData={dataClassDetail}
          setSelectedData={setSelectedData}
          channel={channel}
          stage={stage}
          refetchDataClass={refetchDataClass}
          refetchClassDetail={refetchClassDetail}
          isLoading={
            isLoadingClassDetail && fetchStatusClassDetail === 'fetching'
          }
        />
      )}
    </>
  );
}
