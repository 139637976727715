import React, { useEffect, useState } from 'react';
import ModalLicenseComponent from '../../Component/Modal/ModalLicenseComponent';
import { Tag } from 'antd';
import * as _ from 'lodash';
import { licenseAPI } from '../../../../Services/license.api';
import { useQuery, useMutation } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { toast } from 'react-toastify';
import Store from '../../../../Store';
import { getCandidateDetailAPI } from '../../../../Services/qaProcess.api';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  modalIsShow: boolean;
  refetchLicense: () => void;
  selectedData: any;
  setSelectedData: React.Dispatch<any>;
  setShowModalDetail: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalNotif: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalNotifIsZel: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalLicenseContainer(props: IProps) {
  const {
    modalIsShow,
    refetchLicense,
    selectedData,
    setSelectedData,
    setShowModalDetail,
    setShowModalNotif,
    setShowModalNotifIsZel,
  } = props;

  const authState: any = Store.getState();
  const username =
    authState.auth[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
    ];

  const [start, setStart] = useState(false);
  const [pendingItemTemp, setPendingItemTemp] = useState<any[]>([]);
  const [listPendingItem, setListPendingItem] = useState<any[]>([]);
  const [countItemPending, setCountItemPending] = useState(1);
  const [candidateClean, setCandidateClean] = useState<boolean | null>(null);
  const [initialValues, setInitialValues] = useState<any>();

  const { data: candidateGroupData, isFetching: isFetchingCandidateGroup } =
    useQuery(
      ['GetCandidateGroupDataAPI', selectedData?.caseId, selectedData?.taskId],
      licenseAPI.getCandidateGroupData,
      {
        enabled: selectedData !== undefined,
        refetchOnWindowFocus: false,
      }
    );

  const { data: candidateDetail } = useQuery(
    ['GetCandidateDetailAPI', selectedData?.code],
    getCandidateDetailAPI,
    {
      enabled: selectedData?.code !== undefined,
    }
  );
  const candidateApprovers = candidateDetail?.[0]?.candidateApprovers?.find(
    (val: any) => val.approverRole === 'RECRUITER'
  );

  const { isFetching: isFetchingCandidateLicense } = useQuery(
    ['GetCandidateLicenseStateAPI', selectedData?.agentNumber],
    licenseAPI.getCandidateLicenseState,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const tempObj = {
          licenseId: res.licenseNumber,
          effectiveDate: res.licenseEffectiveDate,
          expiredDate: res.licenseExpireDate,
          category: res.licenseType,
        };
        setInitialValues(tempObj);
      },
    }
  );

  const { data: examPassedData, isFetching: isFetchingExamPassed } = useQuery(
    ['GetCheckingExamPassedAPI', selectedData?.agentNumber],
    licenseAPI.getCheckingExamPassed,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const { data: examStatusData, isFetching: isFetchingExamStatus } = useQuery(
    ['GetCheckingExamStatusAPI', selectedData?.agentNumber],
    licenseAPI.getCheckingExamStatus,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: candidateDetailData,
    isFetching: isFetchingCandidateDetail,
    refetch: refetchCandidateDetail,
  } = useQuery(
    ['GetCandidateFormDetailAPI', selectedData?.code],
    licenseAPI.getCandidateFormDetailData,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setListPendingItem(res.licensePendingItemList);
        setCountItemPending(res.licensePendingItemList.length || 1);
        setStart(true);
      },
    }
  );

  const { data: candidateFormData, isFetching: isFetchingCandidateForm } =
    useQuery(['GetCandidateAllFormAPI'], licenseAPI.getCandidateAllFormData, {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
    });

  const {
    data: detailExamCandidateData,
    isFetching: isFetchingDetailExamCandidate,
  } = useQuery(
    ['GetDetailExamCandidateAPI', selectedData?.code],
    licenseAPI.getCandidateExamHistory,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const { data: agreementListData, isFetching: isFetchingAgreementList } =
    useQuery(
      ['GetDocumentAgreementAPI', selectedData?.agentLevel],
      licenseAPI.getDocumentAgreementData,
      {
        enabled: selectedData !== undefined,
        refetchOnWindowFocus: false,
      }
    );

  const { data: contentListData, isFetching: isFetchingContentList } = useQuery(
    ['GetContentInquiryAPI', selectedData?.code],
    licenseAPI.getContentInquiryData,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
    }
  );
  const listFilenet: any = [];
  if (contentListData) {
    for (const iterator of contentListData) {
      const fileName = iterator.properties.filename.split('-')[0];
      listFilenet.push({
        id: `${iterator.properties.objectid}_${iterator.id}`,
        name: fileName,
      });
    }
  }

  const mutatePaymentSlipChecking = useMutation(
    licenseAPI.mutatePaymentSlipChecking,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        if (candidateClean)
          toast.success('Berhasil Dilanjutkan Ke Waiting For Exam Result');
        else toast.success('Berhasil Dilanjutkan Ke Uploading Payment Slip');
        refetchLicense();
        handleCancel();
        refetchCandidateDetail();
      },
    }
  );

  const mutateSubmitLicense = useMutation(licenseAPI.mutateSubmitLicense, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      toast.success('Berhasil Diajukan');
      refetchLicense();
      handleCancel();
    },
  });

  const mutateSubmitLicenseVaricent = useMutation(
    licenseAPI.mutateSubmitLicenseVaricent,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {},
    }
  );

  const mutateSubmitLicenseActivation = useMutation(
    licenseAPI.mutateSubmitLicenseActivation,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success('Berhasil Diajukan');
        handleCancel();
        refetchLicense();
      },
    }
  );

  const mutateUploadContent = useMutation(licenseAPI.mutateUploadContent, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      refetchCandidateDetail();
    },
  });

  useEffect(() => {
    if (candidateGroupData?.dataGroupDetail?.isSynchronized) {
      refetchLicense();
    }
    // eslint-disable-next-line
  }, [candidateGroupData]);

  const detailCandidateValue = selectedData ? selectedData : '';

  const handleCancel = () => {
    setInitialValues(undefined);
    setSelectedData(undefined);
    setPendingItemTemp([]);
    setListPendingItem([]);
    setCandidateClean(null);
    setCountItemPending(1);
    setShowModalDetail(false);
  };

  const handleSubmit = (values: any) => {
    if (detailCandidateValue.progressStatus === 'QA Clean') {
      mutateSubmitLicense.mutate({
        ...selectedData,
        candidateVariable: values?.category,
        username,
      });
    } else {
      if (examStatusData?.isExamPassed !== 'Passed') {
        setShowModalNotif(true);
      } else if (
        (examStatusData?.isExamPassed === 'Passed' &&
          examStatusData?.isZel &&
          !examPassedData?.isElearningPassed &&
          !examPassedData?.isAbsent) ||
        (examStatusData?.isExamPassed === 'Passed' &&
          !examStatusData?.isZel &&
          examPassedData?.isElearningPassed &&
          examPassedData?.isAbsent) ||
        examStatusData?.isZel
      ) {
        mutateSubmitLicenseVaricent.mutate({ ...selectedData, username });
        mutateSubmitLicenseActivation.mutate({
          agentNumber: selectedData.agentNumber,
          licenseId: values?.licenseId,
          effectiveDate: values?.effectiveDate,
          expiredDate: values?.expiredDate,
        });
      } else if (
        examStatusData?.isExamPassed === 'Passed' &&
        !examStatusData?.isZel &&
        !examPassedData?.isElearningPassed
      ) {
        setShowModalNotifIsZel(true);
      }
    }
  };

  const handleClean = (form: FormApi<any, Partial<any>>) => {
    setCandidateClean(true);
    form.change('noteTemplates', null);
    form.change('customNote', null);
  };

  const handleNotClean = () => setCandidateClean(false);

  const handleAddPendingItem = (values: any) => {
    const inputValue =
      values?.noteType === 'template'
        ? values.noteTemplates
        : values.customNote;
    const newData: any = {
      config: {
        pendingStatus: 'active',
        name: `pendingLicense${countItemPending}`,
      },
      template: 'LabelField',
      title: inputValue,
      type: 'string',
      id: Math.random(),
    };
    setCountItemPending(countItemPending + 1);
    setPendingItemTemp([...pendingItemTemp, newData]);
  };

  const handleDeletePendingTemp = (id: number) => {
    const filteredData = pendingItemTemp.filter(obj => obj.id !== id);
    setPendingItemTemp(filteredData);
  };

  const handleSubmitPendingItem = (form: FormApi<any, Partial<any>>) => {
    const mergeListItemPending = listPendingItem.concat(pendingItemTemp);
    setListPendingItem(mergeListItemPending);
    form.change('noteTemplates', null);
    form.change('noteType', null);
    form.change('customNote', null);
  };

  const handleSubmitPaymentSlipChecking = () => {
    const newSchema =
      candidateDetailData?.schemaCandidateData?.schema.properties;
    if (candidateClean) {
      mutatePaymentSlipChecking.mutate({
        ...selectedData,
        username,
        schemaCandidate: candidateDetailData?.schemaCandidateData,
        pendingItemLicense: [],
        isClean: true,
      });
      const schemaPendingItems = newSchema?.aajichecking?.properties
        .pendingitems
        ? newSchema.aajichecking.properties.pendingitems.properties
        : {};

      const listItemPendingLicense = Object.values(schemaPendingItems);
      setListPendingItem(listItemPendingLicense);
      setCountItemPending(listItemPendingLicense.length + 1);
    } else {
      mutatePaymentSlipChecking.mutate({
        ...selectedData,
        username,
        schemaCandidate: candidateDetailData?.schemaCandidateData,
        pendingItemLicense: pendingItemTemp,
        isClean: false,
      });
    }
  };

  const handleChangePendingStatus = (e: string, path: string) => {
    const newSchema =
      candidateDetailData?.schemaCandidateData?.schema.properties || {};

    const subStringPath = path.substring(0, 6);
    if (subStringPath === 'pendin') {
      for (const iterator of listPendingItem) {
        if (iterator.config.name === path) {
          iterator.config.pendingStatus = e ? 'active' : 'finish';
        }
      }
    } else if (subStringPath === 'aaji.p') {
      const aajiPath = `${path}.config.pendingStatus`;
      if (e) _.set(newSchema, aajiPath, 'active');
      else _.set(newSchema, aajiPath, 'finish');
    } else {
      const newPath =
        subStringPath === 'custom'
          ? `qachecking.properties.customNote.properties.${path}.config.pendingStatus`
          : `qachecking.properties.${path}.config.pendingStatus`;
      if (e) _.set(newSchema, newPath, 'active');
      else _.set(newSchema, newPath, 'finish');
    }
  };

  const handleUploadContent = (file: any, additionalMeta: any) => {
    mutateUploadContent.mutate({
      additionalMeta,
      detailCandidateLicense: selectedData,
      detailCandidateQa: null,
      documentsContent: candidateGroupData?.documentContentData,
      file,
      schemaCandidate: candidateDetailData?.schemaCandidateData,
    });
  };

  const tagStat = (tagStatus: string, color: string) => {
    return (
      <Tag color={color} style={{ float: 'right' }}>
        <div className={'tagStatusModal'}>{tagStatus}</div>
      </Tag>
    );
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case 'QA Clean': {
        return tagStat('QA Clean', '#009EE0');
      }
      case 'Uploading Payment Slip': {
        return tagStat('Uploading Payment Slip', '#32BFB6');
      }
      case 'Payment Slip Checking': {
        return tagStat('Payment Slip Checking', '#5D4CD5');
      }
      case 'DOSD Need to Activated': {
        return tagStat('DOSD Need to Activated', '#A648B8');
      }
      case 'Waiting For Exam Result': {
        return tagStat('Waiting For Exam Result', '#F8B720');
      }
      case 'Exam Passed': {
        return tagStat('Exam Passed', '#E9406E');
      }
      case 'Waiting for Training': {
        return tagStat('Waiting For Training', '#FB6915');
      }
      case 'CPD Training': {
        return tagStat('CPD Training', '#B16A19');
      }
      case 'Agent Activated': {
        return tagStat('Agent Activated', '#3ACE3A');
      }
    }
    return null;
  };

  const renderValue = (name: string, row: any) => {
    const values = row.row.original.fromto;
    const newValues =
      name === 'from' ? values.split('-')[0] : values.split('-')[1];

    return <div>{newValues}</div>;
  };

  const column = {
    beneficiary: [
      {
        Header: 'Nama Lengkap',
        accessor: 'fullname',
      },
      {
        Header: 'Hubungan dengan Anda',
        accessor: 'relationship',
      },
      {
        Header: 'Pekerjaan',
        accessor: 'job',
      },
      {
        Header: 'Telepon Rumah',
        accessor: 'homephone',
      },
      {
        Header: 'Nomor HP',
        accessor: 'mobilephone',
      },
      {
        Header: 'Alamat',
        accessor: 'address',
      },
    ],
    workexperience: [
      {
        Header: 'Nama Perusahaan',
        accessor: 'companyname',
      },
      {
        Header: 'Bidang Usaha',
        accessor: 'businessfields',
      },
      {
        Header: 'Jabatan',
        accessor: 'position',
      },
      {
        Header: 'Dari',
        Cell: row => renderValue('from', row),
      },
      {
        Header: 'Sampai',
        Cell: row => renderValue('to', row),
      },
      {
        Header: 'Alasan Keluar',
        accessor: 'resigningreason',
      },
    ],
  };

  return (
    <ModalLicenseComponent
      candidateApprovers={candidateApprovers}
      clean={candidateClean}
      column={column}
      detailCandidate={selectedData}
      detailExam={detailExamCandidateData?.examDtos || []}
      documentsContent={candidateGroupData?.documentContentData}
      examStatus={examStatusData}
      handleAddPendingItem={handleAddPendingItem}
      handleCancel={handleCancel}
      handleChangePendingStatus={handleChangePendingStatus}
      handleClean={handleClean}
      handleDelete={handleDeletePendingTemp}
      handleNotClean={handleNotClean}
      handleSubmit={handleSubmit}
      handleSubmitPaymentSlipChecking={handleSubmitPaymentSlipChecking}
      handleSubmitPendingItem={handleSubmitPendingItem}
      handleUploadContent={handleUploadContent}
      imageHistory={candidateDetailData?.imageHistoryList || []}
      initialValues={initialValues}
      isLoading={
        isFetchingCandidateGroup ||
        isFetchingCandidateLicense ||
        isFetchingExamPassed ||
        isFetchingExamStatus ||
        isFetchingCandidateDetail ||
        isFetchingCandidateForm ||
        isFetchingDetailExamCandidate ||
        isFetchingAgreementList ||
        isFetchingContentList
      }
      isLoadingImageHistory={isFetchingCandidateDetail}
      isInitialLoading={selectedData && !start}
      isLoadingPaymentSlipChecking={mutatePaymentSlipChecking.isLoading}
      isSuccessUploadPaymentSlip={mutateUploadContent.isSuccess}
      listAgreement={agreementListData || []}
      listContent={contentListData || []}
      listFilenet={listFilenet}
      listPendingItem={listPendingItem}
      listPendingItemTemp={pendingItemTemp}
      listQaPendingItem={candidateDetailData?.qaPendingItemList || []}
      modalIsShow={modalIsShow}
      pendingItem={candidateDetailData?.licensePendingItemList || []}
      renderStatus={renderStatus}
      schema={candidateFormData}
      submission={candidateGroupData?.dataGroupDetail}
    />
  );
}
