import React from 'react';
import { Field } from 'react-final-form';
import { classParticipantLevel } from '../../../../../App/Enums';
import CButton from '../../../../../Assets/Components/CButton';
import { multipleCheckbox } from '../../../../../Assets/Components/CCheckbox';
import { ExclamationCircleFilled } from '@ant-design/icons';

interface IProps {
  handleReset: () => void;
  handleAssignParticipant: () => void;
  editMode: boolean;
  modalAction: string;
  selectedData: any;
  formValues?: any;
}

export default function ModuleModalParticipantComponent(props: IProps) {
  const {
    handleAssignParticipant,
    editMode,
    modalAction,
    selectedData,
  } = props;

  const btnAddParticipant = [
    {
      type: 'primary',
      id: 'btnAssignParticipantModule',
      className: 'btnAssignParticipant',
      content: 'Assign Participant',
      onClick: handleAssignParticipant,
      disabled: editMode
        ? selectedData?.isParticipantAssigned === true
          ? true
          : selectedData?.isAutoAssign === true
          ? false
          : true
        : true,
    },
  ];
  return (
    <>
      <div className="disclaimerParticipant">
        <div>
          <ExclamationCircleFilled className="iconWarningModule" />
        </div>
        <ul>
          <li>
            Participant akan ditambahkan jika
            <span className="spanDisclaimerModule">Sub-Module </span>sudah
            selesai dibuat
          </li>
          <li>
            Silahkan assign participant di bagian
            <span className="spanDisclaimerModule">Edit Module</span> untuk
            menambahkan participant
          </li>
        </ul>
      </div>
      <p className="labelModule">Select Level</p>
      <div className="containerFormLicense">
        <Field
          name="participant.agentLevels"
          component={multipleCheckbox}
          options={classParticipantLevel}
          className="multipleCheckboxLicenseStatus"
          disabled
        />
      </div>
      {modalAction === 'update' ? (
        <CButton buttonData={btnAddParticipant} />
      ) : null}
      <hr className="lineSubModulLevelMapping" />
    </>
  );
}
