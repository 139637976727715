import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Row, Switch } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import { contentManagementModuleAPI } from '../../../../Services/ElearningContentManagement/module.api';
import ModuleComponent from '../Component/ModuleComponent';
import ModuleModalContainer from './ModuleModalContainer';
import ModuleModalMigrationContainer from './ModuleModalMigrationContainer';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  channel: any;
  stage: any;
  setTabData: (val: any) => void;
  setAllDataList: (val: any) => void;
  token?: any;
  selectedStatus?: string;
  selectedData?: any;
}

export default function ModuleContainer({
  channel,
  stage,
  setTabData,
  setAllDataList,
}: IProps) {
  const [filter, setFilter] = useState<{
    isActive: string;
    isCertificate: string;
  }>();
  const [selectedData, setSelectedData] = useState();
  const [modalIsShow, setModalIsShow] = useState(false);
  const [modalMigrateIsShow, setModalMigrateIsShow] = useState(false);
  const [dataModule, setDataModule] = useState<any[]>([]);

  const {
    data: oriDataModule,
    refetch: refetchModule,
    isLoading: isLoadingModule,
    isRefetching: isRefetchingModule,
  } = useQuery(
    ['GetContentManagementModuleAPI', { stageId: stage?.id }],
    contentManagementModuleAPI.getModule,
    {
      enabled: stage?.id !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const moduleList = [...res];
        moduleList
          .sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
          .sort((a, b) => a.sequence - b.sequence);
        setDataModule(moduleList);
      },
    }
  );

  const mutate = useMutation(contentManagementModuleAPI.mutateModule, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="updateElearningModuleStatusSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetchModule();
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const deleteModule = useMutation(contentManagementModuleAPI.deleteModule, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="deleteElearningModuleSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetchModule();
    },
  });

  const handleOnClick = () => setModalIsShow(true);

  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const oriObj = row.row.original;
    const action = status === true ? 'meng-aktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => {
        mutate.mutate({
          ...oriObj,
          codemiUserLevelId: stage.codemiUserLevelId,
          isActive: status,
          moduleId: id,
        });
      },
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Module "${row.row.original.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = row => {
    const rowData = row.row.original;
    const processEnabled = isChecked =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => deleteModule.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Module "${rows.name}"`, toastrConfirmOptions);
    };
    const dataButton = [
      {
        id: `btnDeleteChannel`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
      },
      {
        id: `btnEditChannel`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'btnEdit',
      },
      {
        id: 'btnNextChannel',
        icon: 'ArrowRightOutlined',
        onClick: () => {
          oriDataModule
            .sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
            .sort((a, b) => a.sequence - b.sequence);
          setDataModule(oriDataModule);
          setFilter(undefined);
          setTabData(prev => ({ ...prev, module: rows }));
          setAllDataList(prev => ({ ...prev, moduleList: dataModule }));
        },
        className: 'btnNext',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderCol = (label: string, content: string) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { createdDate, totalSubModule, totalContent } = row.original;
    const moduleCreateDate = moment(createdDate).format('DD-MMM-YYYY');
    return (
      <div>
        {renderCol(
          'Create Date',
          moduleCreateDate === '01-Jan-0001' ? '-' : moduleCreateDate
        )}
        {renderCol(
          'Sub-Module',
          totalSubModule === null || totalSubModule === ''
            ? '-'
            : totalSubModule
        )}
        {renderCol(
          'Total Content',
          totalContent === null || totalContent === '' ? '-' : totalContent
        )}
      </div>
    );
  }, []);

  const renderCertificate = row => {
    const { isCertificate } = row.row.original;
    return isCertificate ? 'Yes' : 'No';
  };

  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Module Name',
      accessor: 'name',
    },
    {
      Header: 'Predecessor',
      accessor: 'predecessorDesc',
    },
    {
      Header: 'Sequence',
      accessor: 'sequence',
    },
    {
      Header: 'Certificate',
      accessor: 'isCertificateDesc',
      Cell: row => renderCertificate(row),
    },
    {
      Header: 'Status',
      Cell: row => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: row => renderAction(row),
    },
  ];

  const handleApplyfilter = () => {
    let moduleList = [...oriDataModule];
    if (filter) {
      if (filter.isActive !== undefined && filter.isActive !== '')
        moduleList = moduleList.filter(item =>
          filter.isActive === 'true' ? item.isActive : !item.isActive
        );
      if (filter.isCertificate !== undefined && filter.isCertificate !== '')
        moduleList = moduleList.filter(item =>
          filter.isCertificate === 'true'
            ? item.isCertificate
            : !item.isCertificate
        );
      moduleList
        .sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
        .sort((a, b) => a.sequence - b.sequence);
    }
    setDataModule(moduleList);
  };

  const handleRefresh = () => {
    refetchModule();
    setFilter(undefined);
  };

  return (
    <>
      <ModuleComponent
        columnData={columnData}
        moduleList={dataModule}
        isLoading={isLoadingModule || isRefetchingModule}
        handleAdd={handleOnClick}
        handleRefresh={handleRefresh}
        renderRowSubComponent={renderRowSubComponent}
        setFilter={setFilter}
        handleApplyfilter={handleApplyfilter}
        selectedFilter={filter}
      />
      {modalIsShow && (
        <ModuleModalContainer
          channel={channel}
          stage={stage}
          moduleList={oriDataModule}
          modalAction={selectedData ? 'update' : 'register'}
          modalIsShow={modalIsShow}
          setModalIsShow={setModalIsShow}
          refetchModule={refetchModule}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
      {modalMigrateIsShow && (
        <ModuleModalMigrationContainer
          channel={channel}
          modalIsShow={modalMigrateIsShow}
          setModalIsShow={setModalMigrateIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
    </>
  );
}
