import { Tabs, Tag } from 'antd';
import React from 'react';
import FeedbackNonSelfLearningTabContainer from '../Container/Tabs/FeedbackNonSelfLearningTabContainer';
import FeedbackSelfLearningTabContainer from '../Container/Tabs/FeedbackSelfLearningTabContainer';
import QuestionTabContainer from '../Container/Tabs/QuestionTabContainer';
import SlideTabContainer from '../Container/Tabs/SlideTabContainer';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface IProps {
  currentTab: string;
  handleChangeTab: (val: any) => void;
  channel: any;
  stage: any;
  module: any;
  submodule: any;
  allDataList: {
    channelList: any;
    stageList: any;
    moduleList: any;
    submoduleList: any;
  };
  setTabData: (val: any) => void;
  setAllDataList: (val: any) => void;
  setContent?: (val: any) => void;
  setCurrentTab?: (val: any) => void;
}

const { TabPane } = Tabs;

export default function ContentComponent(props: IProps) {
  const {
    currentTab,
    stage,
    submodule,
    handleChangeTab,
  } = props;

  const renderDisclaimerNotExam = contentName => {
    if (submodule && (submodule.type === 'Exam' || submodule.type === 'Quiz')) {
      return (
        <Tag
          color="warning"
          icon={<ExclamationCircleOutlined />}
          className="tagsContentManagement"
        >
          <p className="textTagsContentManagement">
            Content {contentName} tidak dapat diakses, karena{' '}
            <b>Sub-module "{`${submodule.name}`}" berjenis exam/quiz</b>
          </p>
        </Tag>
      );
    }
    return null;
  };
  const renderDisclaimerQuestion = () => {
    if (submodule && submodule.type === null) {
      return (
        <Tag
          color="warning"
          icon={<ExclamationCircleOutlined />}
          className="tagsContentManagement"
        >
          <p className="textTagsContentManagement">
            Content Question tidak dapat diakses, karena{' '}
            <b>
              Sub-module "{`${submodule.name}`}"{' '}
              <span style={{ color: '#D82D2D' }}>BUKAN</span> exam/quiz
            </b>
          </p>
        </Tag>
      );
    }
    return null;
  };
  const renderDisclaimer = () => {
    if (currentTab === 'Slide') {
      return renderDisclaimerNotExam('Slide');
    } else if (currentTab === 'Question') {
      return renderDisclaimerQuestion();
    } else if (currentTab === 'FeedbackSelfLearning') {
      return renderDisclaimerNotExam('Feedback (Self Learning)');
    }
    return renderDisclaimerNotExam('Feedback (Non-Self Learning)');
  };

  return (
    <>
      {renderDisclaimer()}
      <Tabs className="dynamicTabs" type="card" onChange={handleChangeTab}>
        <TabPane tab="Slide" key="Slide" className="tabsPaneDynamic">
          <SlideTabContainer
            currentTab={currentTab}
            stage={stage}
            submodule={submodule}
          />
        </TabPane>
        <TabPane tab="Question" key="Question" className="tabsPaneDynamic">
          <QuestionTabContainer
            currentTab={currentTab}
            stage={stage}
            submodule={submodule}
          />
        </TabPane>
        <TabPane
          tab="Feedback (Self Learning)"
          key="FeedbackSelfLearning"
          className="tabsPaneDynamic"
        >
          <FeedbackSelfLearningTabContainer
            currentTab={currentTab}
            stage={stage}
            submodule={submodule}
          />
        </TabPane>
        <TabPane
          tab="Feedback (Non-Self Learning)"
          key="FeedbackOffline"
          className="tabsPaneDynamic"
        >
          <FeedbackNonSelfLearningTabContainer
            currentTab={currentTab}
            stage={stage}
            submodule={submodule}
          />
        </TabPane>
      </Tabs>
    </>
  );
}
