import moment from 'moment';
import instance from './interceptor';
import { UseQueryOptions } from '@tanstack/react-query';
import {
  IResultCandidateFormDao,
  IResultCandidateGroupDao,
  IResultCandidateLicenseDao,
  IResultCheckExamPassedDao,
  IResultCheckExamStatusDao,
  IResultContentDao,
  IResultContentInquiryDao,
  IResultDetailCandidateExamDao,
  IResultDocumentAgreementDao,
  IResultLicenseListDao,
} from '../Modules/License/DAO/license.dao';
import { convertBase64 } from '../App/Helper';

const getContentDataById = async (
  params: UseQueryOptions
): Promise<IResultContentDao> => {
  const contentId = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Content/GetContent?contentId=${contentId}`
  );
  return data;
};

const getCandidateAllFormData = async (): Promise<IResultCandidateFormDao> => {
  const { data } = await instance.get(`/form?Code=FRMCR0001`);
  return data[0];
};

const getLicenseUploadData = async () => {
  const { data } = await instance.get(
    `/Candidate/file/upload/history?uploadHistoryType=License`
  );
  return data;
};

const getLicenseData = async (
  params: UseQueryOptions
): Promise<IResultLicenseListDao[]> => {
  const progressStatus = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Candidate/workflow/group/DOSDLicense?candidateVariable=${
      progressStatus === 'SEMUA' || progressStatus === undefined
        ? ''
        : progressStatus
    }`
  );
  return data;
};

const getCandidateGroupData = async (
  params: UseQueryOptions
): Promise<{
  dataGroupDetail: IResultCandidateGroupDao;
  documentContentData: any;
}> => {
  const caseId = params?.queryKey?.[1];
  const taskId = params?.queryKey?.[2];
  const { data: dataGroupDetail }: { data: IResultCandidateGroupDao } =
    await instance.get(
      `Candidate/workflow/group/detail?caseId=${caseId}&taskId=${taskId}`
    );

  const listDocument: any = [];
  const valueDocument = Object.values(dataGroupDetail.submission.documents);
  const keyDocument = Object.keys(dataGroupDetail.submission.documents);
  keyDocument.forEach((element, index) => {
    listDocument.push({ title: element, idContent: valueDocument[index] });
  });
  listDocument.push({
    title: 'idcard',
    idContent: dataGroupDetail.submission.personaldata.profile.idcard,
  });
  listDocument.push({
    title: 'signature',
    idContent: dataGroupDetail.submission.useragreements.list.signature,
  });

  const content: any = {};
  for (const iterator of listDocument) {
    const { idContent, title } = iterator;
    if (idContent) {
      if (title === 'otherattachments') {
        const listId = idContent.split('|');
        for (let index = 0; index < listId.length; index++) {
          const element = listId[index];
          const { data: contentData } = await instance.get(
            `/Content/GetContent?contentId=${element}`
          );
          const newData: any = { ...contentData };
          newData.content = `data:${contentData.properties.mime};base64, ${contentData.content}`;
          content[`otherattachments${index}`] = newData;
          dataGroupDetail.submission.documents[`otherattachments${index}`] =
            element;
        }
      } else {
        const contentId = idContent.split('|');
        const { data: contentData } = await instance.get(
          `/Content/GetContent?contentId=${contentId[0]}`
        );
        const newData = { ...contentData };
        newData.content = `data:${contentData.properties.mime};base64, ${contentData.content}`;
        content[title] = newData;
      }
    }
  }
  return { dataGroupDetail, documentContentData: content };
};

const getCheckingExamPassed = async (
  params: UseQueryOptions
): Promise<IResultCheckExamPassedDao> => {
  const agentCode = params?.queryKey?.[1];
  const { data } = await instance.get(
    `Elearning/check/exam/passed/${agentCode}`
  );
  return data;
};

const getCandidateExamHistory = async (
  params: UseQueryOptions
): Promise<IResultDetailCandidateExamDao> => {
  const candidateCode = params?.queryKey?.[1];
  const { data } = await instance.get(
    `Candidate/history/participant?candidateCode=${candidateCode}`
  );
  return data;
};

const getCheckingExamStatus = async (
  params: UseQueryOptions
): Promise<IResultCheckExamStatusDao> => {
  const payeeId = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Candidate/workflow/checking/exam/status/${payeeId}`
  );
  return data;
};

const getCandidateLicenseState = async (
  params: UseQueryOptions
): Promise<IResultCandidateLicenseDao> => {
  const payeeId = params?.queryKey?.[1];
  const { data } = await instance.get(`Candidate/license/${payeeId}`);
  return data;
};

const downloadLicenseReport = async () => {
  const url = `/Candidate/report/aaji/checking/license/xlsx`;
  await instance
    .get(`${url}`, {
      responseType: 'blob',
      method: 'GET',
    })
    .then((response: any) => {
      const DateNow = moment(new Date()).format('DDMMYYYY');
      const fileName = `AAJI-Checking-Licensing-${DateNow}.xlsx`;
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
};

const getDocumentAgreementData = async (
  params: UseQueryOptions
): Promise<IResultDocumentAgreementDao[]> => {
  const candidateLevel = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Configuration/disclaimer/module/mapping?menuType=Agreement&agreementType=${
      candidateLevel === 'FC' ? 'NonLeader' : 'Leader'
    }`
  );
  return data;
};

const getContentInquiryData = async (
  params: UseQueryOptions
): Promise<IResultContentInquiryDao[]> => {
  const candidateCode = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Content/InquiryContent?repositorySymbolicName=submission&contentProperties=candidatecode%3D${candidateCode}&contentProperties=isMovedToFilenet%3Dtrue`
  );
  return data;
};

const getCandidateFormDetailData = async (
  params: UseQueryOptions
): Promise<{
  schemaCandidateData: IResultCandidateFormDao;
  imageHistoryList: any;
  licensePendingItemList: any;
  qaPendingItemList: any;
}> => {
  const candidateCode = params?.queryKey?.[1];
  const { data: candidateFormData } = await instance.get(
    `/form?Code=FRMCR0001.${candidateCode}`
  );
  const qaPendingItemList: any = [];
  const noteAaji =
    candidateFormData[0].schema.properties.aaji.properties.note.properties;
  const listNoteAaji = Object.keys(noteAaji);
  if (listNoteAaji.length > 0) {
    for (const iterator of listNoteAaji) {
      const data = noteAaji[iterator];
      data.config.path = `aaji.properties.note.properties.${iterator}`;
      qaPendingItemList.push(data);
    }
  }

  const qachecking =
    candidateFormData[0].schema.properties.qachecking.properties;
  const listKeyQaChecking = Object.keys(qachecking);
  if (listKeyQaChecking.length > 0) {
    for (const iterator of listKeyQaChecking) {
      const data = qachecking[iterator];
      if (iterator === 'beneficiary' || iterator === 'workexperience') {
        qaPendingItemList.push(data);
      } else if (iterator === 'personaldata') {
        const dataProperties = data.properties;
        const listKeyDataProperties = Object.keys(dataProperties);
        for (const element of listKeyDataProperties) {
          const detailProperties = dataProperties[element]['properties'];
          if (element !== 'taxbankaccount') {
            const listValueDetailProperties = Object.values(detailProperties);
            for (const values of listValueDetailProperties) {
              qaPendingItemList.push(values);
            }
          } else {
            const listKeyDetailProperties = Object.keys(detailProperties);
            for (const x of listKeyDetailProperties) {
              if (x !== 'hastax') {
                const listValuex = Object.values(
                  detailProperties[x]['properties']
                );
                for (const y of listValuex) {
                  qaPendingItemList.push(y);
                }
              } else {
                qaPendingItemList.push(detailProperties[x]['properties']);
              }
            }
          }
        }
      } else {
        const detailProperties = data.properties;
        const listValueDetailProperties = Object.values(detailProperties);
        for (const iterator of listValueDetailProperties) {
          qaPendingItemList.push(iterator);
        }
      }
    }
  }

  const schemaPendingItems = candidateFormData[0].schema.properties.aajichecking
    .properties?.pendingitems
    ? candidateFormData[0].schema.properties.aajichecking.properties
        .pendingitems.properties
    : {};

  const licensePendingItemList = Object.values(schemaPendingItems);

  const dataSchema =
    candidateFormData[0].schema.properties.aajichecking.properties.paymentslip;
  const content: any = [];
  if (dataSchema) {
    const imageHistory = dataSchema.properties.history;
    for (const iterator of imageHistory) {
      const docContent = await instance.get(
        `Content/GetContent?contentId=${iterator}`
      );
      content.push(docContent.data);
    }
  }

  return {
    schemaCandidateData: candidateFormData[0],
    imageHistoryList: content.reverse(),
    licensePendingItemList,
    qaPendingItemList,
  };
};

const mutatePaymentSlipChecking = async (params: any) => {
  const {
    caseId,
    taskId,
    agentLevel,
    isClean,
    pendingItemLicense,
    schemaCandidate,
    username,
  } = params;

  const payload = {
    caseId,
    taskId,
    candidateLevel: agentLevel,
    variables: {
      approver: {
        value: username,
      },
      approve: {
        value: isClean,
      },
      demoteCandidateLevel: {
        value: false,
      },
      candidateLevel: {
        value: agentLevel,
      },
      reason: {
        value: '',
      },
    },
  };

  const newSchema = { ...schemaCandidate };
  for (const iterator of pendingItemLicense) {
    newSchema.schema.properties.aajichecking.properties.pendingitems.properties[
      iterator.config.name
    ] = iterator;
  }

  await instance.post(`/form`, newSchema);
  await instance.post(`/Candidate/workflow/approve/registration`, payload);
  return;
};

const mutateSubmitLicense = async (params: any) => {
  const {
    caseId,
    taskId,
    agentLevel,
    clasificationAgent,
    candidateVariable,
    username,
  } = params;

  const payload = {
    classificationType: clasificationAgent,
    caseId,
    taskId,
    candidateLevel: agentLevel,
    variables: {
      approver: {
        value: username,
      },
      approve: {
        value: true,
      },
      candidateLevel: {
        value: agentLevel,
      },
    },
  };
  await instance.post(
    `/Candidate/workflow/approve/submission/${caseId}/${candidateVariable}`,
    payload
  );
};

const mutateSubmitLicenseVaricent = async (params: any) => {
  const { caseId, taskId, username, agentLevel } = params;

  const payload = {
    caseId,
    taskId,
    candidateLevel: agentLevel,
    variables: {
      approver: {
        value: username,
      },
      approve: {
        value: true,
      },
      demoteCandidateLevel: {
        value: false,
      },
      candidateLevel: {
        value: agentLevel,
      },
    },
  };
  await instance.post(
    `/Candidate/workflow/approve/registration/varicentIntegration`,
    payload
  );
};

const mutateSubmitLicenseActivation = async (params: any) => {
  const { agentNumber, licenseId, effectiveDate, expiredDate } = params;

  const payload = {
    licenseNumber: licenseId,
    licenseEffectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
    licenseExpireDate: moment(expiredDate).format('YYYY-MM-DD'),
  };
  await instance.put(`/Candidate/license/${agentNumber}`, payload);
};

const mutateUploadContent = async (params: any) => {
  const {
    additionalMeta,
    detailCandidateLicense,
    detailCandidateQa,
    documentsContent,
    file,
    schemaCandidate,
  } = params;

  const base64 = await convertBase64(file?.data);

  const payload = {
    repositoryId: additionalMeta?.repositoryId,
    contentId: additionalMeta?.contentId,
    folderId: additionalMeta?.folderId,
    folderSymbolicName: 'submission',
    name: additionalMeta?.field,
    originalFileName: file.name,
    content: base64,
    properties: {
      candidateCode: detailCandidateQa
        ? detailCandidateQa.code
        : detailCandidateLicense
        ? detailCandidateLicense.code
        : '',
      contentId: additionalMeta?.contentId,
      context: 'Candidate Self Registration',
      docId: additionalMeta?.contentId,
      documentCode: additionalMeta?.documentCode,
      documentGroup: additionalMeta?.documentGroup,
      documentType: additionalMeta?.documentType,
      extension: file.extension,
      fieldPath: `FRMCR0001.${additionalMeta?.field}`,
      fileName: `${additionalMeta?.fileName}.${file.extension}`,
      formId: 'FRMCR0001',
      isMovedToFilenet: 'false',
      isoTimestamp: additionalMeta?.isoTimeStamp,
      lastContentId: additionalMeta?.contentId,
      mime: file.type,
      module: 'candidate',
      objectId: '',
      relationId: additionalMeta?.contentId,
      size: file.size,
      timestamp: additionalMeta?.dateNow,
      type: file.type,
    },
  };

  if (additionalMeta.folderId) {
    const { data } = await instance.put(`/Content/UpdateContent`, payload);
    const documents: any = {};
    const docContent = await instance.get(
      `/Content/GetContent?contentId=${data}`
    );
    const detailDocContent = docContent.data;
    const newData: any = detailDocContent;
    newData.content = `data:${detailDocContent.properties.mime};base64, ${detailDocContent.content}`;
    documents[additionalMeta.name] = newData;
    if (additionalMeta.sectionKey === 'licenseForm') {
      const listHistory: any =
        Object.keys(schemaCandidate.schema.properties.aajichecking.properties)
          .length > 0
          ? schemaCandidate.schema.properties.aajichecking.properties
              .paymentslip.properties.history
          : [];
      listHistory.unshift(data);
      const newSchemaCandidate: any = schemaCandidate;
      newSchemaCandidate.schema.properties.aajichecking.properties = {
        paymentslip: {
          config: {},
          type: 'object',
          properties: {
            history: listHistory,
          },
        },
      };
      await instance.post(`/form`, newSchemaCandidate);
    }
  } else {
    const { data: contentId } = await instance.post(`/Content/CreateContent`, {
      ...payload,
      repositorySymbolicName: 'submission',
    });
    const { data: detailDocContent } = await instance.get(
      `/Content/GetContent?contentId=${contentId}`
    );
    const documents = { ...documentsContent };
    const newData = { ...detailDocContent };
    newData.content = `data:${detailDocContent.properties.mime};base64, ${detailDocContent.content}`;
    documents[additionalMeta.name] = newData;
    if (additionalMeta.sectionKey === 'licenseForm') {
      const listHistory: any =
        Object.keys(schemaCandidate.schema.properties.aajichecking.properties)
          .length > 0
          ? schemaCandidate.schema.properties.aajichecking.properties
              .paymentslip.properties.history
          : [];
      listHistory.push(contentId);
      const newSchemaCandidate = { ...schemaCandidate };
      newSchemaCandidate.schema.properties.aajichecking.properties = {
        paymentslip: {
          config: {},
          type: 'object',
          properties: {
            history: listHistory,
          },
        },
      };
      await instance.post(`/form`, newSchemaCandidate);
    }
  }
};

export const licenseAPI = {
  downloadLicenseReport,
  getCheckingExamPassed,
  getCandidateLicenseState,
  getCandidateExamHistory,
  getCheckingExamStatus,
  getContentDataById,
  getCandidateGroupData,
  getDocumentAgreementData,
  getContentInquiryData,
  getCandidateAllFormData,
  getCandidateFormDetailData,
  mutatePaymentSlipChecking,
  mutateSubmitLicense,
  mutateSubmitLicenseActivation,
  mutateSubmitLicenseVaricent,
  mutateUploadContent,
  getLicenseUploadData,
  getLicenseData,
};
