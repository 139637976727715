import { Col, Empty, Row, Form as FormANTD } from 'antd';
import { licenseStatus, progressStatus } from '../../../../App/Enums';
import CButton from '../../../../Assets/Components/CButton';
import { InputDatePicker } from '../../../../Assets/Components/CInputDate';
import React from 'react';
import Table from '../../../../Assets/Components/CTable';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import Select from '../../../../Assets/Components/Select';
import { FormApi } from 'final-form';

interface IProps {
  columnData: any;
  dataFilter: any[];
  handleDownload: () => void;
  handleFilter: (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
    values: any
  ) => void;
  handleRefresh: (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>
  ) => void;
  isGlobalLoading: boolean;
  isLoading: boolean;
  licenseList: any[];
  renderRowSubComponent: any;
}

export default function LicenseTabListComponent(props: IProps) {
  const {
    columnData,
    dataFilter,
    handleDownload,
    handleFilter,
    handleRefresh,
    isLoading,
    licenseList,
    renderRowSubComponent,
  } = props;

  return (
    <div className="containerTabWhiteZurich">
      <Form onSubmit={() => {}}>
        {formProps => {
          const { form, values } = formProps;

          const disabledDate = d =>
            !d || d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'));

          const buttonData = [
            {
              type: 'primary',
              className: 'spacingBtnSecondRow2 btnRadius10',
              icon: 'SearchOutlined',
              onClick: () => handleFilter(form, values),
              content: 'Cari',
              id: 'btnSearchLicenseList',
            },
            licenseList.length !== 0
              ? {
                  type: 'ghost',
                  icon: 'ReloadOutlined',
                  onClick: () => handleRefresh(form),
                  content: 'Refresh',
                  id: 'btnRefreshLicenseList',
                  className: 'spacingBtnSecondRow2 btnRadius10',
                }
              : '',
          ];
          const buttonDataSecondRow = [
            {
              type: 'primary',
              icon: null,
              content: 'Tambah Baru',
              id: 'btnAddNew',
              className: 'spacingBtnSecondRow2 btnRadius10',
            },
            {
              type: 'ghost',
              icon: 'DownloadOutlined',
              onClick: handleDownload,
              content: 'Unduh AAJI Checking',
              id: 'btnDownloadLicenseReport',
              className: 'spacingBtnSecondRow2 btnRadius10',
            },
          ];

          return (
            <FormANTD layout="vertical">
              <Row gutter={20}>
                <Col span={4}>
                  <Field
                    name="progressStatus"
                    component={Select}
                    idComponent="inputProgressStatus"
                    dataOption={progressStatus}
                    defaultItemName="Semua"
                    label={'Progress'}
                  />
                </Col>
                <Col span={4}>
                  <Field
                    name="licenseStatus"
                    component={Select}
                    idComponent="inputLicenseStatus"
                    dataOption={licenseStatus}
                    defaultItemName="Semua"
                    label={'Status Lisensi'}
                  />
                </Col>
                <Col span={10}>
                  <Field
                    name="dateFilter"
                    component={InputDatePicker}
                    idComponent="inputDateFilterListLicense"
                    label={'Tanggal'}
                    typeDate="dateRangePicker"
                    disabledDate={disabledDate}
                    placeholder={['Tanggal Mulai', 'Tanggal Berakhir']}
                  />
                </Col>
                <Col span={6} className={'btnSearchLicense'}>
                  <CButton
                    buttonData={buttonData}
                    buttonFloat={licenseList.length !== 0 ? 'right' : 'left'}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
              {licenseList.length !== 0 ? (
                <Row className={'spacingBtnSecondRow'}>
                  <CButton
                    buttonData={buttonDataSecondRow}
                    buttonFloat={'right'}
                    isLoading={isLoading}
                  />
                </Row>
              ) : (
                ''
              )}
            </FormANTD>
          );
        }}
      </Form>

      {licenseList.length !== 0 ? (
        <Table
          isLoading={isLoading}
          columns={columnData}
          data={dataFilter}
          pagination={true}
          useFilterGlobal={true}
          renderRowSubComponent={renderRowSubComponent}
        />
      ) : (
        <Empty style={{ margin: 'auto' }} />
      )}
      {/* {loadingProcess()} */}
    </div>
  );
}
