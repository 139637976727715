import { Button, Skeleton } from 'antd';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Link } from 'react-router-dom';
import CButton from '../../../../../Assets/Components/CButton';
import CIcon from '../../../../../Assets/Components/CIcon';
import { InputText } from '../../../../../Assets/Components/CInput';
import { CInputSwitch } from '../../../../../Assets/Components/CInputSwitch';
import CModalRFF from '../../../../../Assets/Components/CModalRFF';
import CShowDetailFeedback from '../../../../../Assets/Components/CShowDetailFeedback';
import CTransferTextRFF from '../../../../../Assets/Components/CTransferTextRFF';
import Select from '../../../../../Assets/Components/Select';

interface IProps {
  disabled: boolean;
  isShow: boolean;
  modalAction: string;
  editMode: boolean;
  isLoading: any;
  handleCancel: () => void;
  onSubmit: (values: any) => void;
  handleResetQuestion: () => void;
  handleSetListBank: React.Dispatch<React.SetStateAction<never[]>>;
  handleSetListSelected: React.Dispatch<React.SetStateAction<never[]>>;
  listFeedback: any[];
  selectedFeedback: any[];
  dataDetail?: any;
  initialValues?: any;
}

export default function FeedbackNonSelfLearningModalComponent(props: IProps) {
  const {
    isShow,
    isLoading,
    handleCancel,
    handleResetQuestion,
    handleSetListBank,
    handleSetListSelected,
    onSubmit,
    modalAction,
    editMode,
    listFeedback,
    selectedFeedback,
    initialValues,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update'
          ? 'Edit Feedback Non-Self Learning'
          : 'Feedback Non-Self Learning Baru'}
      </h3>
    );
  };

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { values, form } = propsContent;

    if (modalAction === 'update') {
      return (
        <Skeleton loading={isLoading} active>
          <div className="container_input_feedback">
            <Field
              name="category"
              component={InputText}
              label="Feedback Category"
              idComponent="inputFeedbackCategory"
              disabled={true}
              className="inputTextCategory"
            />
            <Field
              name="sequence"
              component={Select}
              label="Sequence"
              idComponent="inputSequence"
              dataOption={[]}
              disabled={true}
              className="inputTextCategory"
            />
            <b className="labelSwitchStatus">Status</b>
            <Field
              name="isActive"
              component={CInputSwitch}
              disabled={!editMode}
              className="inputStatus"
            />
            <span className="labelInfoStatusInContent">
              {values && values.isActive ? 'Active' : 'Not Active'}
            </span>
          </div>
          <CShowDetailFeedback
            listMultipleChoice={
              initialValues ? initialValues.multipleChoiceQuestions : []
            }
            listAnswer={initialValues ? initialValues.answers : []}
            listFreeText={initialValues ? initialValues.essayQuestions : []}
          />
        </Skeleton>
      );
    } else {
      return (
        <Skeleton loading={isLoading} active>
          <div className="disclaimerQuestionContent">
            <div className="textBold">Catatan:</div>
            <p>
              Jika ingin Menambahkan, Mengedit atau Menghapus daftar pertanyaan
              dibawah. Silahkan mengakses menu{' '}
              <span className="textBold">{`Elearning > `}</span>
              <span className="textBold textUnderline">
                <Link to={'/elearning/feedbackbank'}>Feedback Bank</Link>
              </span>
            </p>
          </div>
          <CTransferTextRFF
            titleContentLeft={'Feedback Bank'}
            titleContentRight={'Selected Question'}
            fieldType="Rff"
            listBank={listFeedback}
            listSelected={selectedFeedback}
            formName="examList"
            actionSetListBank={handleSetListBank}
            actionSetListSelected={handleSetListSelected}
            form={form}
            formValues={values}
          />
        </Skeleton>
      );
    }
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = propsFooter => {
    const { invalid } = propsFooter;
    const dataButtonModalRegister = [
      {
        type: 'primary',
        className: 'btnResetContentQuestion',
        id: 'btnResetContentQuestion',
        content: 'Reset',
        onClick: handleResetQuestion,
        disabled: selectedFeedback.length > 0 ? false : true,
      },
      {
        type: 'primary',
        className: 'btnCreateContentQuestion',
        id: 'btnCreateContentQuestion',
        content: buttonContent(modalAction, editMode),
        onClick: onSubmit,
        disabled: selectedFeedback.length > 0 ? false : true,
      },
    ];

    if (modalAction === 'update')
      return (
        <Button
          loading={isLoading}
          className={
            editMode
              ? invalid
                ? 'btnSubmitDisabled'
                : 'btnSubmit'
              : 'btnSubmit'
          }
          icon={<CIcon type="CheckOutlined" />}
          disabled={modalAction === 'update' && !editMode ? false : invalid}
          id="btnSubmit"
          htmlType="submit"
        >
          {buttonContent(modalAction, editMode)}
        </Button>
      );
    else return <CButton buttonData={dataButtonModalRegister} />;
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}
