import { Layout, Spin } from 'antd';

import HeaderContainer from '../Container/HeaderContainer';
import { LoadingOutlined } from '@ant-design/icons';
import React, { Dispatch, SetStateAction } from 'react';
import SiderContainer from '../Container/SiderContainer';
import moment from 'moment';
import packageJson from '../../../../package.json';
import SessionTimeoutContainer from '../Container/SessionTimeoutContainer';

interface IProps {
  siderIsCollapse: boolean;
  setSiderIsCollapse: Dispatch<SetStateAction<boolean>>;
  children: any;
  isGlobalLoading: any;
}

export default function TemplateComponent(props: IProps) {
  const { children, isGlobalLoading, siderIsCollapse, setSiderIsCollapse } =
    props;
  const { Footer, Content } = Layout;
  const year = moment().format('YYYY');
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 40, color: '#003399', marginTop: 70 }}
      spin
    />
  );

  return (
    <Spin
      indicator={antIcon}
      tip="Mohon Ditunggu..."
      spinning={isGlobalLoading}
      className="globalLoading"
    >
      <SessionTimeoutContainer />
      <Layout className="layoutTemplate">
        <SiderContainer siderIsCollapse={siderIsCollapse} />
        <Layout
          className={`${
            !siderIsCollapse ? 'siderCollapseFalse' : 'siderCollapseTrue'
          }`}
        >
          <HeaderContainer
            siderIsCollapse={siderIsCollapse}
            setSiderIsCollapse={setSiderIsCollapse}
          />
          <Content className="contentTemplate">{children}</Content>
          <Footer className="footerTemplate">
            Copyright © {`${year}`} Zurich Indonesia
            <span className="cmsVersionFooter">
              Zurich CMS v.{packageJson.version}
            </span>
          </Footer>
        </Layout>
      </Layout>
    </Spin>
  );
}
