import moment from 'moment';
import history from '../App/History';
interface ISetToken {
  token: string;
  tokenRefresh: string;
  permission: any;
}

const initialState: any = {
  token: '',
  tokenRefresh: '',
  permission: [],
  lastActionTimeStamp: moment().format('YYYY-MM-DD HH:mm:ss'),
  auth: {
    user: {
      name: '',
    },
  },
};

function permissionToArray(rawPermission: any) {
  let prevPath = '';
  let tempPermission: any[] = [];
  let loop = 0;
  const permission: any[] = [];
  for (const iterator of rawPermission) {
    const arrAcc = iterator.split('.');
    const lastIndexAcc = arrAcc.length - 1;
    const pAccess = arrAcc[lastIndexAcc];
    arrAcc.splice(lastIndexAcc, 1);
    const cPath = `/${arrAcc.join('/')}`;
    if (loop !== 0 && prevPath !== cPath) {
      permission.push({
        [prevPath !== '/dashboard' ? prevPath : '/']: tempPermission,
      });
      tempPermission = [];
    }
    prevPath = cPath;
    tempPermission.push(pAccess);
    loop++;
    if (loop === rawPermission.length) {
      permission.push({ [prevPath]: tempPermission });
    }
  }
  return permission;
}

function processPermission(data: any, rawPermission: any) {
  const permission = permissionToArray(rawPermission);
  data.permission = permission;
  for (const key in data.permission) {
    if (Object.prototype.hasOwnProperty.call(data?.permission, key)) {
      const element = data.permission[key];
      for (const x in element) {
        if (Object.prototype.hasOwnProperty.call(element, x)) {
          const y = element[x];

          replacePermission(data, x, y);
        }
      }
    }
  }
}

function replacePermission(data: any, x, y) {
  const permission = data.permission;
  if (x === '/sec/rol') {
    permission.push({
      '/role/permission': y,
    });
  }
  if (x === '/sec/use') {
    permission.push({
      '/role/user': y,
    });
  }
}

const authSlice = (set: any, get: any) => ({
  ...initialState,
  setConfiguration: (configuration: any) => {
    set(() => ({
      configuration,
    }));
  },
  setToken: ({ token, tokenRefresh, permission }: ISetToken) =>
    set(() => {
      const data = { token, tokenRefresh, permission };
      if (token && tokenRefresh) {
        const momentNow = moment().format('YYYY-MM-DD HH:mm:ss');
        const lastActionTimeStamp = momentNow;
        const jwtDecode = require('jwt-decode');
        const decodedAuth = jwtDecode(token);
        const decodedAuthRole =
          decodedAuth[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ];
        const rawPermission: any[] = decodedAuthRole ? decodedAuthRole : [];
        const regex = /(?:^|\b)(c|d|r|u)(?:$|\b)/g;
        const oneWordPermission: any[] = [];

        const manyWordPermission: any[] = [];
        for (const menu of rawPermission) {
          const arr = menu.split('.');
          if (arr[1].match(regex)) oneWordPermission.push(menu);
          else manyWordPermission.push(menu);
        }
        oneWordPermission.sort((a, b) => a.localeCompare(b));
        manyWordPermission.sort((a, b) => a.localeCompare(b));

        processPermission(data, [...oneWordPermission, ...manyWordPermission]);
        return {
          token,
          tokenRefresh,
          auth: decodedAuth,
          permission: data.permission,
          lastActionTimeStamp,
        };
      } else {
        return initialState;
      }
    }),
  logOut: () => {
    const urlCallback = get().configuration?.callbackUrl;
    set(() => initialState);
    if (urlCallback) {
      window.location.replace(urlCallback);
    } else {
      history.push('/login');
    }
  },
});

export default authSlice;
